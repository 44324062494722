import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  ON_GET_SEARCHED_ORDERS_HISTORY_LOADING,
  ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS,
  ON_GET_SEARCHED_ORDERS_HISTORY_FAIL,
  CLEAR_SEARCHED_ORDERS_HISTORY,
} from "./types"
let abortSearchedOrdersHistoryController

export const searchOrdersHistory = (searchData, pageNumber, pageSize) => {
  if (abortSearchedOrdersHistoryController) {
    abortSearchedOrdersHistoryController.abort()
  }

  abortSearchedOrdersHistoryController = new AbortController()
  const signal = abortSearchedOrdersHistoryController.signal

  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
    signal,
  }

  return (dispatch) => {
    dispatch({
      type: ON_GET_SEARCHED_ORDERS_HISTORY_LOADING,
    })
    axios
      .post(api.searchOrdersHistory, searchData, {
        options,
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      })

      .then((res) => {
        dispatch({
          type: ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_SEARCHED_ORDERS_HISTORY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearSearchedOrdersHistory = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCHED_ORDERS_HISTORY,
  })
}
