import React, { Component } from "react"
import { connect } from "react-redux"

import {
  LabeledTextInput,
  LabeledSelect,
  Spinner,
  ValidationMessage,
  CheckboxAndLabel,
  LabeledDatePicker,
  LabeledTextarea,
} from "../../../common"
import {
  DISCOUNT_TYPES_ENUM,
  CouponOrderTypes_ENUM,
} from "../../../../utils/EnumsAndConsts"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../Modals/NotificationModal"

import {
  cleanCouponForm,
  editCouponForm,
  cleanCouponNameCheck,
  checkIfCouponNameExist,
  createCoupon,
} from "../../../../actions"

import "./style.css"

const NewCoupon_TitleText = "יצירת קופון"
const NewCoupon_Name_InputLabelText = "שם הקופון"
const NewCoupon_Description_InputLabelText = "תאור"
const NewCoupon_Discount_InputLabelText = "הנחה"
const NewCoupon_DiscountToShow_InputLabelText = 'הנחה להצגה (עם מע"מ) '

const NewCoupon_DiscountType_InputLabelText = "סוג ההנחה"

const NewCoupon_OrderType_InputLabelText = "סוג הזמנה"
const NewCoupon_StartDate_InputLabelText = "תאריך התחלה"
const NewCoupon_ExpiredDate_InputLabelText = "תאריך סיום"
const NewCoupon_IsActive_InputLabelText = "קופון פעיל"

const NewCoupon_SaveButtonText = "צור קופון"

const NewCoupon_CouponNameIsExist_Text = "שם הקופון כבר קיים במערכת."
const NewCoupon_FillAnotherContent_Text = "אנא מלא ערך אחר בשדה."

const NewCoupon_CreateSuccessed_Text = "הקופון יוצר בהצלחה"

class NewCoupon extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCreateCouponButtonDisabled: false,
      startDate: "",
      expiredDate: "",
      validField: {
        couponName: {
          type: "couponName",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        couponDescription: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discount: {
          type: "number",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discountToShow: {
          type: "number",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discountType: {
          type: "requiredSelect",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        orderType: {
          type: "requiredSelect",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },

        startDate: {
          type: "date",
          isRequired: true,
          isChecked: false,
          isValid: false,
          compareMinValue: null,
          compareMaxValue: null,
        },
        expiredDate: {
          type: "date",
          isRequired: true,
          isChecked: false,
          isValid: false,
          compareMinValue: null,
          compareMaxnValue: null,
        },
      },
    }

    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.cleanCouponForm()
    this.props.cleanCouponNameCheck()
  }
  componentWillUnmount() {
    this.setState({
      isCreateCouponButtonDisabled: false,
      startDate: "",
      expiredDate: "",
    })
    this.props.cleanCouponForm()
    this.props.cleanCouponNameCheck()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  calcDiscount(discountToShow, discountType) {
    if (discountType - 0) {
      return discountToShow / 1.18
    }
    return discountToShow
  }

  handleFieldChange(inputName, inputValue) {
    const { coupon } = this.props
    let _coupon = { ...coupon }

    if (inputName === "discountType") {
      let discountFieldType = inputValue - 0 === 0 ? "percent" : "number"
      this.state.validField.discount.type = discountFieldType

      _coupon = {
        ..._coupon,
        ["discount"]: this.calcDiscount(coupon.discountToShow, inputValue),
      }
      this.runValidation("discount", _coupon.discount)
    }

    if (inputName === "discountToShow") {
      _coupon = {
        ..._coupon,
        ["discount"]: this.calcDiscount(inputValue, _coupon.discountType),
      }
      this.runValidation("discount", _coupon.discount)
    }

    _coupon = {
      ..._coupon,
      [inputName]: inputValue,
    }

    this.props.editCouponForm(_coupon)

    this.runValidation(inputName, inputValue)
  }

  handleDateChange(inputName, event) {
    this.handleFieldChange(inputName, event.target.value)
    this.setState({
      [inputName]: event.target.value,
    })
  }

  checkIfCouponNameExist(couponName) {
    if (!isValidField(couponName, "couponName", true)) {
      return
    }

    const { couponNameCheck } = this.props
    if (couponNameCheck.couponName == couponName) {
      if (couponNameCheck.isCheckedCouponName) {
        return
      }
      this.props.checkIfCouponNameExist(couponName)
    }
    this.props.checkIfCouponNameExist(couponName)
  }

  onSubmitButtonClick() {
    const {
      couponName,
      couponDescription,
      discount,
      discountToShow,
      //discountWithVat,
      discountType,
      orderType,
      //startDate, expiredDate,
      isActive,
    } = this.props.coupon

    let newCoupon = {
      couponName,
      couponDescription,
      discount,
      discountToShow,
      //discountWithVat,
      discountType,
      orderType,
      startDate: this.state.startDate,
      expiredDate: this.state.expiredDate,
      isActive,
    }

    //VALIDATION
    if (!this.isValidCoupon(newCoupon)) {
      //TODO MESSAGE
      console.log("Is NOT VALID COUPON!!!")
      return
    }
    this.setState({ isCreateCouponButtonDisabled: true })
    console.log("NEW COUPON:")
    console.log(newCoupon)
    this.props.createCoupon(newCoupon)
  }

  isValidCoupon(newCoupon) {
    const fieldsList = [
      "couponName",
      "couponDescription",
      "discount",
      "startDate",
      "expiredDate",
    ]
    this.runValidationList(fieldsList, newCoupon)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, coupon) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, coupon[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    if (inputName === "expiredDate") {
      let dExpired = new Date(this.state.startDate.split("-"))
      dExpired.setDate(dExpired.getDate() + 1)
      field.compareMinValue = dExpired
    }

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    let checkCouponExpiredDate = false
    if (inputName === "startDate" && field.isValid) {
      checkCouponExpiredDate = true
    }
    this.state.checkCouponExpiredDate = checkCouponExpiredDate
    this.setState({
      ...this.state,
      validField,
      //checkCouponExpiredDate,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  cleanCouponName() {
    this.props.cleanCouponNameCheck()
    this.onFieldFocus("couponName")
  }

  closeForm() {
    this.props.history.push("/CouponsScreen")
  }

  render() {
    const { coupon, couponNameCheck, isLoading, isCreated } = this.props

    const {
      couponName,
      couponDescription,
      discount,
      discountType,
      orderType,
      //discountToShow,
      //startDate, expiredDate,
      isActive,
    } = coupon

    let { discountToShow } = coupon
    const { isExistCouponName, isCheckedCouponName } = couponNameCheck

    const discountTypesList = DISCOUNT_TYPES_ENUM
    const orderTypesList = CouponOrderTypes_ENUM
    const fieldTypeList = FIELD_TYPE_ENUM
    const { validField, startDate, isCreateCouponButtonDisabled } = this.state
    const expiredDateIsDisabled = startDate === null || startDate === ""

    if (coupon.discountType === "") {
      this.handleFieldChange("discountType", 0)
    }
    if (coupon.orderType === "") {
      this.handleFieldChange("orderType", 1)
    }
    const { checkCouponExpiredDate } = this.state
    if (checkCouponExpiredDate) {
      this.runValidation("expiredDate", this.state.expiredDate)
    }

    if (couponName === "" && (discount === undefined || discount === 0)) {
      discountToShow = 0
    }

    if (isLoading) {
      return (
        <div className="new-coupon spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    return (
      <>
        <h1>{NewCoupon_TitleText}</h1>
        <div className="ss-container new-coupon">
          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewCoupon_Name_InputLabelText}
              value={couponName}
              inputSize={20}
              onChange={(e) => {
                this.handleFieldChange("couponName", e.target.value)
              }}
              onBlur={(e) => {
                this.checkIfCouponNameExist(e.target.value)
              }}
              onFocus={(e) => {
                this.cleanCouponName(e)
              }}
            />
            <ValidationMessage
              isShow={isCheckedCouponName}
              isShowOk={!isExistCouponName}
              isShowError={isExistCouponName}
              className="field-valid"
              errorMessage={
                NewCoupon_CouponNameIsExist_Text +
                " " +
                NewCoupon_FillAnotherContent_Text
              }
            />
            <ValidationMessage
              isShow={validField.couponName.isChecked}
              isShowOk={false}
              isShowError={!validField.couponName.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.couponName.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <CheckboxAndLabel
              checked={isActive}
              onChange={(e) => {
                this.handleFieldChange("isActive", e.target.checked)
              }}
            >
              {NewCoupon_IsActive_InputLabelText}
            </CheckboxAndLabel>
          </div>
          <div className="section-info-row">
            <LabeledTextarea
              labelText={NewCoupon_Description_InputLabelText}
              value={couponDescription}
              inputSize={600}
              onChange={(e) => {
                this.handleFieldChange("couponDescription", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("couponDescription")
              }}
              onBlur={(e) => {
                this.runValidation("couponDescription", couponDescription)
              }}
            />
            <ValidationMessage
              isShow={validField.couponDescription.isChecked}
              isShowOk={false}
              isShowError={!validField.couponDescription.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.couponDescription.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewCoupon_DiscountToShow_InputLabelText}
              value={discountToShow}
              type="number"
              inputSize={3}
              onChange={(e) => {
                this.handleFieldChange("discountToShow", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("discountToShow")
              }}
              onBlur={(e) => {
                this.runValidation("discountToShow", discountToShow)
              }}
            />
            <ValidationMessage
              isShow={validField.discount.isChecked}
              isShowOk={false}
              isShowError={!validField.discount.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.discount.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />

            <LabeledSelect
              labelText={NewCoupon_DiscountType_InputLabelText}
              options={discountTypesList}
              value={discountType}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("discountType", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("discountType")
              }}
              onBlur={(e) => {
                this.runValidation("discountType", discountType)
              }}
            />

            <div className="section-info-row">
              <LabeledTextInput
                labelText={NewCoupon_Discount_InputLabelText}
                value={discount}
                type="number"
                inputSize={3}
                onChange={(e) => {
                  this.handleFieldChange("discount", e.target.value)
                }}
                onFocus={(e) => {
                  this.onFieldFocus("discount")
                }}
                onBlur={(e) => {
                  this.runValidation("discount", discount)
                }}
                disabled={true}
              />
            </div>
            {/**
                     
                     <ValidationMessage 
                        isShow={validField.discountType.isChecked} 
                        isShowOk={false} 
                        isShowError={!validField.discountType.isValid} 
                        className='field-valid' 
                        errorMessage={fieldTypeList[validField.discountType.type].errMessage + " " + fieldTypeList['required'].errMessage}/>
                     */}
          </div>
          <div className="section-info-row">
            <LabeledSelect
              labelText={NewCoupon_OrderType_InputLabelText}
              options={orderTypesList}
              value={orderType}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("orderType", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("orderType")
              }}
              onBlur={(e) => {
                this.runValidation("orderType", orderType)
              }}
            />

            {/*
                       <ValidationMessage 
                        isShow={validField["orderType"].isChecked} 
                        isShowOk={false} 
                        isShowError={!validField["orderType"].isValid} 
                        className='field-valid' 
                        errorMessage={fieldTypeList[validField.orderType.type].errMessage + " " + fieldTypeList['required'].errMessage}/>
                       
                       */}
          </div>

          <div className="section-info-row">
            <LabeledDatePicker
              name="startDate"
              labelText={NewCoupon_StartDate_InputLabelText}
              date={this.state.startDate}
              onChange={this.handleDateChange}
              coupon={coupon}
              editCouponForm={this.props.editCouponForm}
            />
            <ValidationMessage
              isShow={validField.startDate.isChecked}
              isShowOk={false}
              isShowError={!validField.startDate.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.startDate.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />

            <LabeledDatePicker
              name="expiredDate"
              disabled={expiredDateIsDisabled}
              labelText={NewCoupon_ExpiredDate_InputLabelText}
              date={this.state.expiredDate}
              onChange={this.handleDateChange}
            />
            <ValidationMessage
              isShow={validField.expiredDate.isChecked}
              isShowOk={false}
              isShowError={!validField.expiredDate.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.expiredDate.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <button
            type="submit"
            className="ss-button"
            onClick={(e) => this.onSubmitButtonClick(e)}
            disabled={isCreateCouponButtonDisabled}
          >
            {NewCoupon_SaveButtonText}
          </button>
        </div>

        <NotificationModal
          show={isCreated}
          title={NewCoupon_TitleText}
          text={NewCoupon_CreateSuccessed_Text}
          onOkClick={() => this.closeForm()}
        />
      </>
    )
  }
}

const mapStateToProp = ({ coupons, errors }) => {
  const { couponsList, coupon, couponNameCheck, isLoading, isCreated } = coupons
  const { unauthorized } = errors
  return {
    couponsList,
    coupon,
    couponNameCheck,
    isLoading,
    isCreated,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  cleanCouponForm,
  editCouponForm,
  cleanCouponNameCheck,
  checkIfCouponNameExist,
  createCoupon,
})(NewCoupon)
