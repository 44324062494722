export const calcPriceAfterDiscountWithVat = (
  orderItems,
  discountType,
  discount
) => {
  if (discount === 0) {
    return calcListPriceNoCompensationWithVat(orderItems)
  }

  //discount > 0
  let newTotalPrice = calcTotalBeforeVatAndDiscount(orderItems)

  if (discountType === 0) {
    // isDiscountTypePercentage..?

    newTotalPrice = calcTotalWithPercentageDiscountBeforeVat(
      newTotalPrice,
      discount
    ) //newTotalPrice - newTotalPrice * discount * 0.01;
  } else {
    newTotalPrice = calcTotalWithSumDiscountBeforeVat(newTotalPrice, discount)
  }

  if (newTotalPrice < 0) {
    newTotalPrice = 0
  }

  return calcWithVat(newTotalPrice)
}

export const calcWithVat = (priceBeforeVat) => {
  return Number.parseFloat(priceBeforeVat * 1.18).toFixed(2)
}

export const calcTotalBeforeVatAndDiscount = (orderItems) => {
  let totalBeforeVatAndDiscount = 0
  for (let i = 0; i < orderItems.length; i++) {
    let itemPrice = 0
    let itemPriceWithoutVat = Number.parseFloat(orderItems[i].itemPrice) / 1.18
    itemPrice = orderItems[i].quantity * itemPriceWithoutVat
    totalBeforeVatAndDiscount =
      Number.parseFloat(totalBeforeVatAndDiscount) +
      Number.parseFloat(itemPrice)
  }

  return totalBeforeVatAndDiscount
}

export const calcListPriceNoCompensationWithVat = (orderItems) => {
  let totalWithVat = orderItems.reduce((accumolator, item) => {
    return item.processName === "Compensation"
      ? accumolator
      : accumolator + item.quantity * Number.parseFloat(item.itemPrice)
  }, 0)

  return Number.parseFloat(totalWithVat).toFixed(2)
}

export const calcTotalWithSumDiscountBeforeVat = (
  totalBeforeVatAndDiscount,
  discount
) => {
  let total = totalBeforeVatAndDiscount
  if (discount > 0) {
    total = totalBeforeVatAndDiscount.toFixed(2) - discount.toFixed(2)
  }

  return Number.parseFloat(total)
}

export const calcTotalWithPercentageDiscountBeforeVat = (
  totalBeforeVatAndDiscount,
  discount
) => {
  let total = totalBeforeVatAndDiscount
  if (discount > 0) {
    const discountValue = (
      (totalBeforeVatAndDiscount * discount) /
      100
    ).toFixed(2)

    total = totalBeforeVatAndDiscount.toFixed(2) - discountValue
  }

  return Number.parseFloat(total)
}
