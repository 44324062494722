import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Link } from "react-router-dom"
import StationsPage from "./StationsPage"
import EditStation from "./EditStation"
import NewStation from "./NewStation"
import StationsNotifications from "./StationsNotifications"
import SendSMS from "./SendSMS"
import Routes from "./Routes"
import RouteStations from "./RouteStations"
import CitiesScreen from "../CitiesScreen"
import RouteAreaCodeScreen from "./RouteAreaCodeScreen/RouteAreaCodeScreen"
import StationTasksScreen from "./StationTasksScreen/StationTasksScreen"
import BranchCard from "./BranchCard/BranchCard"
import ExternalDriversScreen from "./ExternalDriversScreen"
import StationSacksScreen from "./StationSacksScreen/StationSacksScreen"

import {
  fetchStations,
  fetchPriceListSelectOptions,
  cleanStations,
} from "../../../actions"
import "./style.css"

class StationsScreen extends Component {
  componentWillUnmount() {
    this.props.cleanStations()
  }

  render() {
    return (
      <section id="stations-section-container">
        <nav id="stations-section-menu">
          <ul>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}`}>עמדות</Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/create`}>עמדה חדשה</Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/notifications`}>התראות</Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/routes`}>
                ניהול מסלולי הפצה
              </Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/route_cities`}>
                עדכון ערים
              </Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/routeAreaCodes`}>
                עדכון קודי אזור
              </Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/stationTasks`}>
                משימות עמדה
              </Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/externalDrivers`}>
                נהגים חיצונים
              </Link>
            </li>
            <li className="stations-section-menu-item">
              <Link to={`${this.props.match.url}/staionSacks`}>שקים</Link>
            </li>
          </ul>
        </nav>
        <main id="stations-section-body">
          <Route
            exact
            path={`${this.props.match.path}`}
            component={StationsPage}
          />
          <Route
            path={`${this.props.match.path}/edit`}
            component={EditStation}
          />
          <Route
            path={`${this.props.match.path}/create`}
            component={NewStation}
          />
          <Route
            path={`${this.props.match.path}/notifications`}
            component={StationsNotifications}
          />
          <Route
            path={`${this.props.match.path}/sendSMS`}
            component={SendSMS}
          />
          <Route path={`${this.props.match.path}/routes`} component={Routes} />
          <Route
            path={`${this.props.match.path}/route_stations`}
            component={RouteStations}
          />
          <Route
            path={`${this.props.match.path}/route_cities`}
            component={CitiesScreen}
          />
          <Route
            path={`${this.props.match.path}/routeAreaCodes`}
            component={RouteAreaCodeScreen}
          />
          <Route
            path={`${this.props.match.path}/stationTasks`}
            component={StationTasksScreen}
          />
          <Route
            path={`${this.props.match.path}/branchCard`}
            component={BranchCard}
          />
          <Route
            path={`${this.props.match.path}/externalDrivers`}
            component={ExternalDriversScreen}
          />
          <Route
            path={`${this.props.match.path}/staionSacks`}
            component={StationSacksScreen}
          />
        </main>
      </section>
    )
  }
}

export default connect(null, {
  fetchStations,
  fetchPriceListSelectOptions,
  cleanStations,
})(StationsScreen)
