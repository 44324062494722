import {
  ON_GET_SEARCHED_ORDERS_HISTORY_LOADING,
  ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS,
  ON_GET_SEARCHED_ORDERS_HISTORY_FAIL,
  CLEAR_SEARCHED_ORDERS_HISTORY,
} from "../actions/types"
import {} from "../utils"

const INIT_STATE = {
  isLoading: false,
  isError: false,
  searchedOrdersHistory: [],
  searchedOrdersHistorySuccess: false,
  searchedOrdersHistoryFail: false,
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
}

const SearchOrdersHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_SEARCHED_ORDERS_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        searchedOrdersHistory: action.payload,
        searchedOrdersHistorySuccess: true,
        searchedOrdersHistoryFail: false,
      }
    case ON_GET_SEARCHED_ORDERS_HISTORY_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        searchedOrdersHistory: [],
        searchedOrdersHistorySuccess: false,
        searchedOrdersHistoryFail: true,
      }
    }
    case CLEAR_SEARCHED_ORDERS_HISTORY: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        searchedOrdersHistory: [],
        searchedOrdersHistorySuccess: false,
        searchedOrdersHistoryFail: false,
      }
    }
    default:
      return state
  }
}

export default SearchOrdersHistoryReducer
