import React, { Component } from "react"
import { connect } from "react-redux"

import {
  LabeledTextInput,
  LabeledSelect,
  Spinner,
  ValidationMessage,
  CheckboxAndLabel,
  LabeledDatePicker,
  LabeledTextarea,
} from "../../../common"
import { getShortDate } from "../../../../utils/FormatedDate"
import {
  CouponOrderTypes_ENUM,
  DISCOUNT_TYPES_ENUM,
} from "../../../../utils/EnumsAndConsts"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../Modals/NotificationModal"
import YesNoModal from "../../../Modals/YesNoModal"

import {
  cleanCouponForm,
  editCouponForm,
  cleanCouponNameCheck,
  checkIfCouponNameExist,
  updateCoupon,
  removeCoupon,
  cleanCouponUpdate,
  cleanCouponRemove,
} from "../../../../actions"

//removeCoupon, updateCoupon, createCoupon, getCouponByName, fetchCoupons

import "./style.css"

const EditCoupon_TitleText = "עדכון קופון"
const NewCoupon_Name_InputLabelText = "שם הקופון"
const NewCoupon_Description_InputLabelText = "תאור"
const NewCoupon_DiscountToShow_InputLabelText = 'הנחה להצגה (עם מע"מ) '
const NewCoupon_Discount_InputLabelText = "הנחה"
const NewCoupon_DiscountType_InputLabelText = "סוג ההנחה"

const NewCoupon_OrderType_InputLabelText = "סוג הזמנה"
const NewCoupon_StartDate_InputLabelText = "תאריך התחלה"
const NewCoupon_ExpiredDate_InputLabelText = "תאריך סיום"
const NewCoupon_IsActive_InputLabelText = "קופון פעיל"

const EditCoupon_CreatedDate = "תאריך יצירה"
const EditCoupon_UpdatedDate = "תאריך עדכון"
const EditCoupon_CreatedBy = "שם יוצר"
const EditCoupon_IsDelete = "הקופון הוסר"
//createdDate, updateDate, createdBy, isDelete,

const EditCoupon_SaveButtonText = "עדכן קופון"
const EditCoupon_RemoveButtonText = "הסר קופון"

const NewCoupon_CouponNameIsExist_Text = "שם הקופון כבר קיים במערכת."
const NewCoupon_FillAnotherContent_Text = "אנא מלא ערך אחר בשדה."

const EditCoupon_UpdateSuccessed_Text = "הקופון עודכן בהצלחה"
const EditCoupon_RemoveTitleText = "הסרת קופון"
const EditCoupon_RemoveSuccessed_Text = "הקופון הוסר בהצלחה"
class EditCoupon extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      startDate: "",
      expiredDate: "",
      createdDate: "",
      updateDate: "",
      isLoading: false,
      showAreYouSureModal: false,
      validField: {
        couponDescription: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discount: {
          type: "number",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discountToShow: {
          type: "number",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        discountType: {
          type: "requiredSelect",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        orderType: {
          type: "requiredSelect",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        startDate: {
          type: "date",
          isRequired: true,
          isChecked: false,
          isValid: false,
          compareMinValue: null,
          compareMaxValue: null,
        },
        expiredDate: {
          type: "date",
          isRequired: true,
          isChecked: false,
          isValid: false,
          compareMinValue: null,
          compareMaxValue: null,
        },
      },
      checkCouponExpiredDate: false,
      //checkCouponDiscount: false,
    }

    this.state = this.INIT_STATE

    this.handleDateChange = this.handleDateChange.bind(this)
    this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this)
    this.onRemoveModalClick = this.onRemoveModalClick.bind(this)
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
  }

  componentWillMount() {
    this.props.cleanCouponNameCheck()
    this.setState(this.INIT_STATE)
  }
  componentWillUnmount() {
    this.props.cleanCouponForm()
    this.props.cleanCouponNameCheck()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }

    const { coupon, isRemoved, isUpdated } = newProps

    this.state = {
      ...this.state,
      startDate: getShortDate(coupon.startDate),
      expiredDate: getShortDate(coupon.expiredDate),
      createdDate: getShortDate(coupon.createdDate),
      updateDate: getShortDate(coupon.updateDate),
    }

    if (isRemoved || isUpdated) {
      this.state = {
        ...this.state,
        isLoading: false,
      }
    }
  }

  calcDiscountToShow(discount, discountType) {
    if (discountType - 0) {
      return (discount * 1.18).toFixed(2)
    }
    return discount
  }

  calcDiscount(discountToShow, discountType) {
    if (discountType - 0) {
      return discountToShow / 1.18
    }
    return discountToShow
  }

  handleFieldChange(inputName, inputValue) {
    const { coupon } = this.props
    let _coupon = {
      ...coupon,
    }
    if (inputName === "discountType") {
      let discountFieldType = inputValue + 0 == 0 ? "percent" : "number"
      this.state.validField.discount.type = discountFieldType

      _coupon = {
        ..._coupon,
        ["discount"]: this.calcDiscount(coupon.discountToShow, inputValue),
      }
      this.runValidation("discount", _coupon.discount)
    }

    if (inputName === "discountToShow") {
      _coupon = {
        ..._coupon,
        ["discount"]: this.calcDiscount(inputValue, _coupon.discountType),
      }
      this.runValidation("discount", _coupon.discount)
    }

    _coupon = {
      ..._coupon,
      [inputName]: inputValue,
    }
    this.props.editCouponForm(_coupon)

    this.runValidation(inputName, inputValue)
  }

  handleDateChange(inputName, event) {
    this.handleFieldChange(inputName, event.target.value)

    this.setState({
      [inputName]: event.target.value,
    })
  }

  isValidCoupon(newCoupon) {
    const fieldsList = [
      "couponDescription",
      "discount",
      "startDate",
      "expiredDate",
    ]
    this.runValidationList(fieldsList, newCoupon)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, coupon) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, coupon[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    if (inputName === "expiredDate") {
      let dExpired = new Date(this.state.startDate.split("-"))
      dExpired.setDate(dExpired.getDate() + 1)
      field.compareMinValue = dExpired
    }

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    let checkCouponExpiredDate = false
    if (inputName === "startDate" && field.isValid) {
      checkCouponExpiredDate = true
    }
    this.state.checkCouponExpiredDate = checkCouponExpiredDate
    this.setState({
      ...this.state,
      validField,
      //checkCouponExpiredDate,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  checkIfCouponNameExist(couponName) {
    //VALIDATE
    if (!isValidField(couponName, "couponName", true)) return

    const { couponNameCheck } = this.props
    if (couponNameCheck.couponName == couponName) {
      if (couponNameCheck.isCheckedCouponName) {
        return
      }
      this.props.checkIfCouponNameExist(couponName)
    }
    this.props.checkIfCouponNameExist(couponName)
  }

  onRemoveButtonClick() {
    this.setState({
      ...this.state,
      showAreYouSureModal: true,
    })
  }
  onRemoveModalClick() {
    const { coupon, removeCoupon } = this.props
    this.state = {
      ...this.state,
      showAreYouSureModal: false,
    }

    removeCoupon(coupon.couponId)
  }

  onSubmitButtonClick() {
    const { coupon } = this.props

    if (!this.isValidCoupon(coupon)) {
      //TODO MESSAGE
      console.log("Is NOT VALID COUPON!!!")
      return
    }
    console.log("COUPON is valid.")
    this.props.updateCoupon(coupon)
  }

  closeForm() {
    this.state = this.INIT_STATE
    this.props.cleanCouponRemove()
    this.props.history.push("/CouponsScreen")
  }

  renderIsDeleted(isDelete) {
    if (isDelete) {
      return (
        <div className="section-info-row is-deleted" disabled={!isDelete}>
          {EditCoupon_IsDelete}
        </div>
      )
    } else {
      return
    }
  }

  render() {
    const {
      coupon,
      couponNameCheck,
      cleanCouponUpdate,
      isLoading,
      isUpdated,
      isRemoved,
    } = this.props

    const {
      couponName,
      couponDescription,
      discount,
      discountToShow,
      discountType,
      orderType,
      //startDate, expiredDate,createdDate, updateDate,
      isActive,
      createdByName,
      isDelete,
    } = coupon

    const {
      startDate,
      expiredDate,
      createdDate,
      updateDate,
      showAreYouSureModal,
      validField,
    } = this.state

    if (isLoading) {
      return (
        <div className="edit-coupon spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    const { isExistCouponName, isCheckedCouponName } = couponNameCheck
    const discountTypesList = DISCOUNT_TYPES_ENUM
    const orderTypesList = CouponOrderTypes_ENUM
    const fieldTypeList = FIELD_TYPE_ENUM

    if (discountType === "") {
      this.handleFieldChange("discountType", 0)
    }
    if (orderType === "") {
      this.handleFieldChange("orderType", 1)
    }

    if (
      (discountToShow === "" || discountToShow === undefined) &&
      discount !== undefined
    ) {
      const disToShow = this.calcDiscountToShow(discount, discountType)
      this.handleFieldChange("discountToShow", disToShow)
    }

    const { checkCouponExpiredDate } = this.state
    if (checkCouponExpiredDate) {
      this.runValidation("expiredDate", this.state.expiredDate)
    }

    return (
      <>
        <div className="ss-container edit-coupon">
          <h1>{EditCoupon_TitleText}</h1>
          {this.renderIsDeleted(isDelete)}
          <div className="section-info-row">
            <LabeledTextInput
              disabled
              labelText={NewCoupon_Name_InputLabelText}
              value={couponName}
              inputSize={20}
              onChange={(e) => {
                this.handleFieldChange("couponName", e.target.value)
              }}
              onBlur={(e) => {
                this.checkIfCouponNameExist(e.target.value)
              }}
              onFocus={(e) => {
                this.props.cleanCouponNameCheck()
              }}
            />
            <ValidationMessage
              isShow={isCheckedCouponName}
              isShowOk={!isExistCouponName}
              isShowError={isExistCouponName}
              className="field-valid"
              errorMessage={
                NewCoupon_CouponNameIsExist_Text +
                " " +
                NewCoupon_FillAnotherContent_Text
              }
            />
          </div>
          <div className="section-info-row">
            <CheckboxAndLabel
              disabled={isDelete}
              checked={isActive}
              onChange={(e) => {
                this.handleFieldChange("isActive", e.target.checked)
              }}
              onFocus={(e) => {
                this.onFieldFocus("isActive")
              }}
            >
              {NewCoupon_IsActive_InputLabelText}
            </CheckboxAndLabel>
          </div>
          <div className="section-info-row">
            <LabeledTextarea
              disabled={isDelete}
              labelText={NewCoupon_Description_InputLabelText}
              value={couponDescription}
              inputSize={600}
              onChange={(e) => {
                this.handleFieldChange("couponDescription", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("couponDescription")
              }}
            />
            <ValidationMessage
              isShow={validField["couponDescription"].isChecked}
              isShowOk={false}
              isShowError={!validField["couponDescription"].isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList["text"].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewCoupon_DiscountToShow_InputLabelText}
              value={discountToShow}
              type="number"
              inputSize={3}
              onChange={(e) => {
                this.handleFieldChange("discountToShow", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("discountToShow")
              }}
              onBlur={(e) => {
                this.runValidation("discountToShow", discountToShow)
              }}
            />

            <ValidationMessage
              isShow={validField["discount"].isChecked}
              isShowOk={false}
              isShowError={!validField["discount"].isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList["number"].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />

            <LabeledSelect
              disabled={isDelete}
              labelText={NewCoupon_DiscountType_InputLabelText}
              options={discountTypesList}
              value={discountType}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("discountType", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("discountType")
              }}
            />
          </div>
          <div>
            <LabeledTextInput
              disabled={true}
              labelText={NewCoupon_Discount_InputLabelText}
              value={discount}
              type="number"
              inputSize={3}
              onChange={(e) => {
                this.handleFieldChange("discount", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("discount")
              }}
            />
          </div>
          <div className="section-info-row">
            <LabeledSelect
              disabled={isDelete}
              labelText={NewCoupon_OrderType_InputLabelText}
              options={orderTypesList}
              value={orderType}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("orderType", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("orderType")
              }}
            />
          </div>
          <div className="section-info-row">
            <LabeledDatePicker
              disabled={isDelete}
              name="startDate"
              labelText={NewCoupon_StartDate_InputLabelText}
              date={startDate}
              onChange={this.handleDateChange}
              coupon={coupon}
              onFocus={(e) => {
                this.onFieldFocus("startDate")
              }}
            />
            <ValidationMessage
              isShow={validField.startDate.isChecked}
              isShowOk={false}
              isShowError={!validField.startDate.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList["date"].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />

            <LabeledDatePicker
              disabled={isDelete}
              name="expiredDate"
              labelText={NewCoupon_ExpiredDate_InputLabelText}
              date={expiredDate}
              onChange={this.handleDateChange}
              onFocus={(e) => {
                this.onFieldFocus("expiredDate")
              }}
            />
            <ValidationMessage
              isShow={validField.expiredDate.isChecked}
              isShowOk={false}
              isShowError={!validField.expiredDate.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList["date"].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              disabled
              labelText={EditCoupon_CreatedDate}
              value={this.state.createdDate}
              type="text"
              inputSize={10}
            />
            <LabeledTextInput
              disabled
              labelText={EditCoupon_UpdatedDate}
              value={this.state.updateDate}
              type="text"
              inputSize={10}
            />
            <LabeledTextInput
              disabled
              labelText={EditCoupon_CreatedBy}
              value={createdByName}
              type="text"
              inputSize={0}
            />
          </div>

          <div className="section-info-row">
            <button
              type="submit"
              disabled={isDelete}
              className="ss-button"
              onClick={(e) => {
                this.onSubmitButtonClick(e)
              }}
            >
              {EditCoupon_SaveButtonText}
            </button>
            <button
              type="submit"
              disabled={isDelete}
              className="ss-button remove-button"
              onClick={() => this.onRemoveButtonClick()}
            >
              {EditCoupon_RemoveButtonText}
            </button>
          </div>
        </div>

        <NotificationModal
          show={isUpdated}
          title={EditCoupon_TitleText}
          text={EditCoupon_UpdateSuccessed_Text}
          onOkClick={() => cleanCouponUpdate()}
        />

        <NotificationModal
          show={isRemoved}
          title={EditCoupon_RemoveTitleText}
          text={EditCoupon_RemoveSuccessed_Text}
          onOkClick={() => this.closeForm()}
        />
        <YesNoModal
          show={showAreYouSureModal}
          title={EditCoupon_RemoveTitleText}
          text={` הקופון יוסר ${couponName}, האם אתה בטוח?`}
          onYesClick={(e) => this.onRemoveModalClick()}
          onNoClick={() => this.setState({ showAreYouSureModal: false })}
        />
      </>
    )
  }
}

const mapStateToProp = ({ coupons, errors }) => {
  const { coupon, couponNameCheck, isLoading, isUpdated, isRemoved } = coupons
  const { unauthorized } = errors
  return {
    coupon,
    couponNameCheck,
    isLoading,
    isUpdated,
    isRemoved,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  cleanCouponForm,
  editCouponForm,
  cleanCouponNameCheck,
  checkIfCouponNameExist,
  updateCoupon,
  removeCoupon,
  cleanCouponUpdate,
  cleanCouponRemove,
})(EditCoupon)
