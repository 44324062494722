import React from "react"
import { BlueButton } from "../BlueButton"

import "./style.css"

const PaginationNext = ({ page, pageStep, currentResponseCount, onClick }) => {
  if (currentResponseCount <= 0 && page === 1) return null

  const renderNextButton = () => {
    if (currentResponseCount < pageStep) return null
    const nextPage = page + 1

    return (
      <BlueButton
        extraClass="pagin-btn next-btn"
        onClick={() => onClick(nextPage)}
        key={nextPage}
      >
        דף הבא...
      </BlueButton>
    )
  }

  const renderPageButton = (pageNum, selected = false) => (
    <BlueButton
      extraClass={`pagin-btn ${selected ? "pagin-selected" : ""}`}
      onClick={() => onClick(pageNum)}
      key={pageNum}
    >
      {pageNum}
    </BlueButton>
  )

  const previousPages = Array.from({ length: page - 1 }, (_, i) => page - i - 1)

  return (
    <div className="pagin-next-wrapper">
      {previousPages.map((pageNum) => renderPageButton(pageNum))}
      {renderPageButton(page, true)}
      {renderNextButton()}
    </div>
  )
}

export { PaginationNext }
