import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import {
  fetchOrderTypes,
  fetchAllStationData,
  fetchAllOrderStatuses,
  fetchRoutes,
  searchOrdersHistory,
  clearSearchedOrdersHistory,
} from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import { BlueButton, PaginationNext, Spinner } from "../../common"
import OrdersHistoryTable from "./OrdersHistoryTable"
import Select from "react-select"
import "./style.scss"

const SearchOrdersHistoryScreen = () => {
  const dispatch = useDispatch()
  const { orderTypes } = useSelector((state) => state.selectOptions)
  const { stationSelectList } = useSelector((state) => state.stationsList)
  const { orderStatusesObj } = useSelector((state) => state.ordersStatuses)
  const {
    isLoading,
    searchedOrdersHistory,
    searchedOrdersHistorySuccess,
    searchedOrdersHistoryFail,
  } = useSelector((state) => state.searchOrdersHistory)
  const { routes } = useSelector((state) => state.routes)
  const [orderTypesList, setOrderTypesList] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [filters, setFilters] = useState({
    orderType: null,
    station: null,
    route: null,
    status: null,
  })
  const [error, setError] = useState("")
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 50

  useEffect(() => {
    fetchIfEmpty(orderTypes, () => dispatch(fetchOrderTypes()))
    fetchIfEmpty(stationSelectList, () => dispatch(fetchAllStationData()))
    fetchIfEmpty(orderStatusesObj, () => dispatch(fetchAllOrderStatuses()))
    fetchIfEmpty(routes, () => dispatch(fetchRoutes()))

    return () => {
      dispatch(clearSearchedOrdersHistory())
    }
  }, [])

  useEffect(() => {
    if (orderTypes.length > 0 && orderTypesList.length === 0) {
      const mappedOrderTypes = orderTypes.map((orderType, index) => ({
        value: index,
        label: orderType,
      }))
      setOrderTypesList(mappedOrderTypes)
    }
  }, [orderTypes])

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleSearch = (page = 1) => {
    setError("")
    setPageNumber(page)

    if (!selectedDate || !startTime || !endTime) {
      setError("אנא בחר תאריך ושעה .")
      return
    }

    const startDateTime = new Date(selectedDate)
    startDateTime.setHours(startTime.getHours(), startTime.getMinutes())

    const endDateTime = new Date(selectedDate)
    endDateTime.setHours(endTime.getHours(), endTime.getMinutes())

    if (endDateTime <= startDateTime) {
      setError("שעת הסיום אינה יכולה להיות לפני או שווה לשעת ההתחלה.")
      return
    }

    if (!phoneNumber && !filters.route && !filters.station) {
      setError("אנא בחר מסלול או עמדה או מספר טלפון.")
      return
    }

    const searchPayload = {
      phoneNumber: phoneNumber || null,
      orderTypes: filters.orderType?.map((ot) => ot.value) || null,
      stationId: filters.station?.value || null,
      routeId: filters.route?.value || null,
      orderStatus: filters.status?.value || null,
      fromDate: startDateTime.toISOString(),
      toDate: endDateTime.toISOString(),
    }
    dispatch(searchOrdersHistory(searchPayload, page, pageSize))
  }

  const handlePageChange = (newPage) => {
    handleSearch(newPage)
  }

  return (
    <div className="searchOrdersHistoryScreen">
      <h1>חיפוש הסטוריית הזמנות</h1>
      <div className="search-filters">
        <div className="row-1">
          <div className="input-container">
            <div className="input-group">
              <label htmlFor="phone-number">מספר טלפון</label>
              <div>
                <input
                  id="phone-number"
                  type="text"
                  placeholder="הכנס מספר טלפון"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="input-group">
              <label htmlFor="order-type">סוג הזמנה</label>
              <Select
                id="order-type"
                placeholder="בחר סוג הזמנה"
                className="select-filter"
                options={orderTypesList}
                onChange={(option) => handleFilterChange("orderType", option)}
                isMulti
                isClearable
              />
            </div>

            <div className="input-group">
              <label htmlFor="route">מסלול</label>
              <Select
                id="route"
                placeholder="בחר מסלול"
                className="select-filter"
                options={routes}
                onChange={(option) => handleFilterChange("route", option)}
                isClearable
              />
            </div>

            <div className="input-group">
              <label htmlFor="station">עמדה</label>
              <Select
                id="station"
                placeholder="בחר עמדה"
                className="select-filter"
                options={stationSelectList}
                onChange={(option) => handleFilterChange("station", option)}
                isClearable
              />
            </div>

            <div className="input-group">
              <label htmlFor="status">סטטוס</label>
              <Select
                id="status"
                placeholder="בחר סטטוס הזמנה"
                className="select-filter"
                options={orderStatusesObj}
                onChange={(option) => handleFilterChange("status", option)}
                isClearable
              />
            </div>
          </div>
        </div>

        <div className="row-2">
          <div className="datepicker-container">
            <div className="time-picker-container">
              <label>תאריך</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                placeholderText="בחר תאריך"
                dateFormat="dd/MM/yyyy"
                popperPlacement="bottom-end"
                maxDate={new Date()}
                showTimeSelect={false}
              />
            </div>

            <div className="time-picker-container">
              <label>משעה</label>
              <DatePicker
                selected={startTime}
                onChange={(time) => setStartTime(time)}
                placeholderText="בחר זמן התחלה"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                timeCaption=""
              />
            </div>

            <div className="time-picker-container">
              <label>עד שעה</label>
              <DatePicker
                selected={endTime}
                onChange={(time) => setEndTime(time)}
                placeholderText="בחר זמן סיום"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                timeCaption=""
              />
            </div>
          </div>

          <BlueButton
            disabled={isLoading}
            extraClass="submit-search"
            onClick={() => handleSearch()}
          >
            חפש
          </BlueButton>
          {isLoading && (
            <div className="spinner">
              <Spinner zoom={0.3} />
            </div>
          )}
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      {searchedOrdersHistorySuccess && searchedOrdersHistory.length > 0 ? (
        <OrdersHistoryTable
          records={searchedOrdersHistory}
          orderStatuses={orderStatusesObj}
        />
      ) : null}
      <PaginationNext
        pageStep={pageSize}
        page={pageNumber}
        onClick={handlePageChange}
        currentResponseCount={searchedOrdersHistory.length}
      />
      {searchedOrdersHistorySuccess && searchedOrdersHistory.length == 0 ? (
        <div className="no-records">לא נמצאו נתונים</div>
      ) : null}
    </div>
  )
}

export default SearchOrdersHistoryScreen
