import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import { getStationsObject } from "../utils/StationConvert"
import { getStationObject } from "../utils/StationConvert"

import {
  ON_GET_STATIONS_SUCCESS,
  ON_LEAVE_STATIONS_SECTION,
  ON_SELECT_STATION,
  ON_CLOSE_STATION_FORM,
  ON_EDIT_STATION,
  ON_UPDATE_STATION_SUCCESS,
  ON_UPDATE_STATION_FAILURE,
  ON_CREATE_STATION_SUCCESS,
  ON_CREATE_STATION_FAILURE,
  ON_READ_LOCKERS_LAYOUT_SUCCESS,
  ON_EDIT_LOCKERS,
  ON_CLOSE_STATION_NOTIFICATION_SUCCESSED,
  ON_CLOSE_STATION_NOTIFICATION_FAILTURE,
  ON_STATION_SHOW_LOADING,
  SEND_STATION_SMS_SUCCESS,
  SEND_STATION_SMS_FAIL,
  CLEAR_STATION_SMS,
  ON_STATIONS_FILTER_BY_TEXT,
  ON_STATIONS_FILTER_BY_PAYMENT_VERSION,
  ON_STATIONS_SET_PAGE,
  ON_STATIONS_FILTER_BY_STATION,
  CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
  GET_STATION_BY_ID_SUCCESS,
  GET_STATION_BY_ID_FAIL,
  GET_POSTAL_CODE_SUCCESS,
  GET_POSTAL_CODE_FAIL,
  GET_POSTAL_CODE_INVALID,
  ON_CLOSE_POSTAL_NOTIFICATION_ERROR,
  ON_CLOSE_POSTAL_NOTIFICATION_INVALID,
  ON_STATIONS_FILTER_BY_IS_ALIVE,
  ON_STATIONS_FILTER_BY_IS_VISIBLE,
  ON_STATIONS_FILTER_BY_ROUTE,
  ON_STATIONS_FILTER_BY_AREA_CODE,
} from "./types"

export const fetchStations = () => {
  return (dispatch) => {
    axios
      .get(api.getLightStations)
      .then((res) => {
        dispatch({
          type: ON_GET_STATIONS_SUCCESS,
          payload: getStationsObject(res.data),
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log("Failed to fetch stations with error:")
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
        //TODO DISPLAY ERROR MESSAGE
      })
  }
}

export const cleanStations = () => {
  return {
    type: ON_LEAVE_STATIONS_SECTION,
  }
}

export const cleanStationForm = () => {
  return {
    type: ON_CLOSE_STATION_FORM,
  }
}

export const selectStation = (station) => {
  console.log(">>> Select station action:")
  console.log(station)
  return {
    type: ON_SELECT_STATION,
    payload: station,
  }
}

export const editStation = (station) => {
  return {
    type: ON_EDIT_STATION,
    payload: station,
  }
}

export const editLockers = (lockers) => {
  return {
    type: ON_EDIT_LOCKERS,
    payload: lockers,
  }
}

export const updateStation = (station) => {
  let formData = new FormData()

  let dtoStation = {
    stationId: station.stationId,
    stationNumber: station.stationNumber,
    branchName: station.branchName,
    branchAddress: station.branchAddress,
    branchName_en: station.branchName_en,
    branchAddress_en: station.branchAddress_en,
    branchDisplayAddress: station.branchDisplayAddress,
    branchDisplayName: station.branchDisplayName,
    barStationNumber: station.barStationNumber,
    isAlive: station.isAlive,
    isDeliveryActive: station.isDeliveryActive ? true : false,
    isLaundryActive: station.isLaundryActive ? true : false,
    isLockerRentActive: station.isLockerRentActive ? true : false,
    isLockerToLockerDeliveryActive: station.isLockerToLockerDeliveryActive
      ? true
      : false,
    isDecathlon: station.isDecathlon ? true : false,
    minimumLaundryEmptyLockers: station.minimumLaundryEmptyLockers,
    priceListId: station.priceListId,
    latitude: station.latitude,
    longitude: station.longitude,
    availabilityHours: station.availabilityHours,
    isDeliveryCompaniesActive: station.isDeliveryCompaniesActive ? true : false,
    stationPriority: station.stationPriority,
    stationPaymentVersion: station.stationPaymentVersion,
    isAmshat: station.isAmshat ? true : false,
    lockers: station.stationLockers.map((locker) => {
      return {
        id: locker.lockerId,
        name: locker.lockerName,
        lockerType: locker.lockerType,
        lockerStatus: locker.lockerStatus,
        isAccessible: locker.isAccessible,
        lockerSize: locker.lockerSize,
      }
    }),
    routeId: station.routeId,
    cityId: station.cityId,
    isAmot: station.isAmot ? true : false,
    isBLE: station.isBLE ? true : false,
    isYadMordechai: station.isYadMordechai ? true : false,
    isSdeMoshe: station.isSdeMoshe ? true : false,
    isOutdoor: station.isOutdoor ? true : false,
    isVisible: station.isVisible ? true : false,
    isAmirim: station.isAmirim ? true : false,
    isReturnExelotActive: station.isReturnExelotActive ? true : false,
    nayaxTerminalSN: station.nayaxTerminalSN,
    postalCode: station.postalCode,
    videoUrl: station.videoUrl,
    isReturnPackageActive: station.isReturnPackageActive ? true : false,
    dhlStationNumber: station.dhlStationNumber,
    dhlRouteCode: station.dhlRouteCode,
    isApple: station.isApple ? true : false,
    isTAU: station.isTAU ? true : false,
    routeAreaCodeId: station.routeAreaCodeId,
    isBerorHayil: station.isBerorHayil ? true : false,
    nearByStationId: station.nearByStationId,
    nearByStationId2: station.nearByStationId2,
    nearByStationId3: station.nearByStationId3,
    isMaayanZvi: station.isMaayanZvi ? true : false,
    isNitzanim: station.isNitzanim ? true : false,
  }

  formData.append("data", JSON.stringify(dtoStation))

  return (dispatch) => {
    axios
      .post(api.updateStation, formData)
      .then((result) => {
        dispatch({
          type: ON_UPDATE_STATION_SUCCESS,
          payload: result.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, result)
      })
      .catch((error) => {
        console.log("Failed update station with error:")
        console.log(error)
        dispatch({
          type: ON_UPDATE_STATION_FAILURE,
          payload: error,
        })
        errorManager.throwDispatch(dispatch, error)
      })
  }
}

export const createStation = (station, layoutFile) => {
  let formData = new FormData()

  let dtoStation = {
    stationNumber: station.stationNumber,
    branchName: station.branchName,
    branchAddress: station.branchAddress,
    branchName_en: station.branchName_en,
    branchAddress_en: station.branchAddress_en,
    branchDisplayAddress: station.branchDisplayAddress,
    branchDisplayName: station.branchDisplayName,
    barStationNumber: station.barStationNumber,
    isAlive: station.isAlive,
    isDeliveryActive: station.isDeliveryActive ? true : false,
    isLaundryActive: station.isLaundryActive ? true : false,
    isLockerRentActive: station.isLockerRentActive ? true : false,
    isLockerToLockerDeliveryActive: station.isLockerToLockerDeliveryActive
      ? true
      : false,
    isDecathlon: station.isDecathlon ? true : false,
    minimumLaundryEmptyLockers: station.minimumLaundryEmptyLockers,
    priceListId: station.priceListId,
    latitude: station.latitude,
    longitude: station.longitude,
    availabilityHours: station.availabilityHours,
    isDeliveryCompaniesActive: station.isDeliveryCompaniesActive ? true : false,
    stationPriority: station.stationPriority,
    stationPaymentVersion: station.stationPaymentVersion,
    isAmshat: station.isAmshat ? true : false,
    lockers: station.stationLockers.map((locker) => {
      return {
        name: locker.lockerName,
        lockerType: locker.lockerType,
        lockerStatus: locker.lockerStatus,
        lockerSize: locker.lockerSize,
        isAccessible: locker.isAccessible,
      }
    }),
    routeId: station.routeId,
    cityId: station.cityId,
    isAmot: station.isAmot ? true : false,
    isBLE: station.isBLE ? true : false,
    isYadMordechai: station.isYadMordechai ? true : false,
    isSdeMoshe: station.isSdeMoshe ? true : false,
    isOutdoor: station.isOutdoor ? true : false,
    isVisible: station.isVisible ? true : false,
    isAmirim: station.isAmirim ? true : false,
    isReturnExelotActive: station.isReturnExelotActive ? true : false,
    nayaxTerminalSN: station.nayaxTerminalSN,
    postalCode: station.postalCode,
    videoUrl: station.videoUrl,
    isReturnPackageActive: station.isReturnPackageActive ? true : false,
    dhlStationNumber: station.dhlStationNumber,
    dhlRouteCode: station.dhlRouteCode,
    isApple: station.isApple ? true : false,
    isTAU: station.isTAU ? true : false,
    routeAreaCodeId: station.routeAreaCodeId,
    isBerorHayil: station.isBerorHayil ? true : false,
    nearByStationId: station.nearByStationId,
    nearByStationId2: station.nearByStationId2,
    nearByStationId3: station.nearByStationId3,
    isMaayanZvi: station.isMaayanZvi ? true : false,
    isNitzanim: station.isNitzanim ? true : false,
  }

  formData.append("data", JSON.stringify(dtoStation))
  formData.append("file", layoutFile)
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.createStation, formData, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_STATION_SUCCESS,
          payload: res,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((error) => {
        console.log("Failed create station with error:")
        console.log(error)
        dispatch({
          type: ON_CREATE_STATION_FAILURE,
          payload: error,
        })
        errorManager.throwDispatch(dispatch, error)
      })
  }
}

export const readLockersLayout = (layoutFile) => {
  let formData = new FormData()
  formData.append("file", layoutFile)
  return (dispatch) => {
    axios
      .post(api.readLockersLayout, formData)
      .then((res) => {
        dispatch({
          type: ON_READ_LOCKERS_LAYOUT_SUCCESS,
          payload: api.readLockersObject(res.data),
        })
      })
      .catch((err) => {
        console.log("Failed to read lockers layout with error:")
        console.log(err)
      })
  }
}

export const closeNotificationSuccessed = () => {
  return {
    type: ON_CLOSE_STATION_NOTIFICATION_SUCCESSED,
  }
}
export const closeNotificationFailture = () => {
  return {
    type: ON_CLOSE_STATION_NOTIFICATION_FAILTURE,
  }
}

export const closeNotificationPostalCodeError = () => {
  return {
    type: ON_CLOSE_POSTAL_NOTIFICATION_ERROR,
  }
}

export const closeNotificationPostalCodeInvalid = () => {
  return {
    type: ON_CLOSE_POSTAL_NOTIFICATION_INVALID,
  }
}

export const showStationLoading = () => {
  return {
    type: ON_STATION_SHOW_LOADING,
  }
}

export const sendStationSMSAction = (stationId, message) => {
  return (dispatch) => {
    axios
      .post(api.sendStationSMS(stationId, message))
      .then((res) => {
        dispatch({
          type: SEND_STATION_SMS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: SEND_STATION_SMS_FAIL,
        })
      })
  }
}

export const clearStationSms = () => {
  return {
    type: CLEAR_STATION_SMS,
  }
}

export const localSearchStationsByText = (searchText) => {
  return {
    type: ON_STATIONS_FILTER_BY_TEXT,
    payload: searchText ? searchText : searchText,
  }
}

export const localSearchStationsByPaymentVersion = (paymentVersion) => {
  return {
    type: ON_STATIONS_FILTER_BY_PAYMENT_VERSION,
    payload: paymentVersion,
  }
}

export const localSearchStationsByIsAlive = (isAlive) => {
  return {
    type: ON_STATIONS_FILTER_BY_IS_ALIVE,
    payload: isAlive,
  }
}

export const localSearchStationsByIsVisible = (isVisible) => {
  return {
    type: ON_STATIONS_FILTER_BY_IS_VISIBLE,
    payload: isVisible,
  }
}

export const localSearchStationsByRoute = (routeId) => {
  return {
    type: ON_STATIONS_FILTER_BY_ROUTE,
    payload: routeId,
  }
}

export const localSearchStationsByAreaCode = (areaCode) => {
  return {
    type: ON_STATIONS_FILTER_BY_AREA_CODE,
    payload: areaCode,
  }
}

export const localSearchStationsByStations = (choosedStationsItems) => {
  return {
    type: ON_STATIONS_FILTER_BY_STATION, //ON_FILTERED_ACTIVE_STATIONS_UPDATE,
    payload: choosedStationsItems,
  }
}
export const localSearchStationsSetPage = (page) => {
  return {
    type: ON_STATIONS_SET_PAGE,
    payload: page,
  }
}
export const clearLocalSearchStations = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
    })
  }
}

export const getStationById = (stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getStationById(stationId), options)
      .then((res) => {
        dispatch({
          type: GET_STATION_BY_ID_SUCCESS,
          payload: getStationObject(res.data),
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_STATION_BY_ID_FAIL,
        })
      })
  }
}

export const getPostalCode = (latitude, longitude) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getPostalCode(latitude, longitude), options)
      .then((res) => {
        if (res.data && res.data.toString().length == 7) {
          dispatch({
            type: GET_POSTAL_CODE_SUCCESS,
            payload: res.data,
          })
        } else {
          dispatch({
            type: GET_POSTAL_CODE_INVALID,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_POSTAL_CODE_FAIL,
        })
      })
  }
}
