import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  getSackSummaryByStation,
  fetchRoutes,
  fetchAllStationData,
} from "../../../../actions"
import "./StationSacksScreen.scss"
import { fetchIfEmpty } from "../../../../utils"

const StationSacksScreen = (props) => {
  const dispatch = useDispatch()
  const { routes } = useSelector((state) => state.routes)
  const { sortedStationsNames } = useSelector((state) => state.stationsList)
  const { unauthorized } = useSelector((state) => state.errors)
  const { sacksSummaryByStationList } = useSelector(
    (state) => state.orderStorageSack
  )
  const [routeFilter, setRouteFilter] = useState("")
  const [stationFilter, setStationFilter] = useState("")

  useEffect(() => {
    if (unauthorized) {
      props.history.push("/")
    }
  }, [])

  useEffect(() => {
    fetchIfEmpty(routes, fetchRoutes)
    fetchIfEmpty(sortedStationsNames, fetchAllStationData)
    fetchIfEmpty(sacksSummaryByStationList, () =>
      dispatch(getSackSummaryByStation())
    )
  }, [routes, sortedStationsNames, sacksSummaryByStationList])

  const filteredStations = sacksSummaryByStationList.filter((station) => {
    const matchesRoute = !routeFilter || station.routeName === routeFilter
    const matchesStation = station.stationNumber
      .toString()
      .includes(stationFilter)
    return matchesRoute && matchesStation
  })

  return (
    <section className="stationSacksScreen">
      <h1>שקים לעמדות</h1>
      <div className="filters">
        <Select
          placeholder="חפש לפי מסלול"
          options={routes}
          onChange={(val) => setRouteFilter(val?.label || "")}
          className="select-route"
          isClearable
        />
        <Select
          placeholder="חפש לפי עמדה"
          options={sortedStationsNames}
          onChange={(val) => setStationFilter(val?.value || "")}
          className="select-station"
          isClearable
        />
      </div>
      <table className="station-sacks-table">
        <thead>
          <tr>
            <th>מספר עמדה</th>
            <th>שם עמדה</th>
            <th>שם מסלול</th>
            <th>שקים במחסן</th>
            <th>שקים אצל נהג</th>
          </tr>
        </thead>
        <tbody>
          {filteredStations.map((station) => (
            <tr key={station.stationId}>
              <td>{station.stationNumber}</td>
              <td>{station.stationName}</td>
              <td>{station.routeName}</td>
              <td>{station.sacksClosedTotal}</td>
              <td>{station.sacksScannedByDriverTotal}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}

export default StationSacksScreen
