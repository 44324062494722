import React from "react"
import { withRouter } from "react-router-dom"

import DrawerItem from "./DrawerItem"

import "./style.css"

const DrawerList = ({ history, userRole }) => {
  return (
    <div className="drawer-list-wrapper">
      {userRole == "WHOperator" ? null : (
        <div className="drawer-list-top-itms">
          <DrawerItem
            icon="fa-home"
            text="דף הבית"
            onClick={(e) => history.push("/HomeScreen")}
          />
          <DrawerItem
            icon="fas fa-clipboard-list"
            text="הזמנות פתוחות"
            onClick={(e) => history.push("/OrdersListScreen")}
          />
          {/**/}
          <DrawerItem
            icon="fa fa-search"
            text="חיפוש הזמנות"
            onClick={(e) => history.push("/OrdersSearchScreen")}
          />
          <DrawerItem
            icon="fa fa-history"
            text="היסטוריה הזמנות"
            onClick={(e) => history.push("/SearchOrdersHistoryScreen")}
          />
          <DrawerItem
            icon="fas fa-pen-square"
            text="עריכת הזמנות"
            onClick={(e) => history.push("/OrdersEditingScreen")}
          />
          <DrawerItem
            icon="fas fa-user-friends"
            text="משתמשים"
            onClick={(e) => history.push("/UsersListScreen")}
          />
          <DrawerItem
            icon="fas fa-user-plus"
            text="יצירת משתמש"
            onClick={(e) => history.push("/UserCreateEditScreen/new")}
          />
          <DrawerItem
            icon="fas fa-shekel-sign"
            text="מחירונים"
            onClick={(e) => history.push("/PriceListsScreen/price-lists")}
          />
          <DrawerItem
            icon="far fa-window-restore"
            text="עמדות"
            onClick={(e) => history.push("/StationsScreen")}
          />
          <DrawerItem
            icon="fas fa-th"
            text="תאים"
            onClick={(e) => history.push("/LockersScreen/lockers")}
          />
          <DrawerItem
            icon="fas fa-bell-o"
            text="התראות"
            onClick={(e) => history.push("/StationsScreen/notifications")}
          />
          <DrawerItem
            icon="far fa-money-bill-wave-alt"
            text="קופונים"
            onClick={(e) => history.push("/CouponsScreen")}
          />
          <DrawerItem
            icon="fa-table"
            text="דוחות"
            onClick={(e) =>
              history.push("/ReportsScreen/detailed-lockers-report")
            }
          />
          <DrawerItem
            icon="fas fa-truck"
            text="ספקים"
            onClick={(e) => history.push("/ServiceSuppliersScreen")}
          />
        </div>
      )}

      <div className="drawer-list-bottom-itms">
        <DrawerItem
          icon="fa-power-off"
          text="Logout"
          onClick={(e) => history.push("/")}
        />
      </div>
    </div>
  )
}

export default withRouter(DrawerList)
