import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import LockersList from "../LockersList"
import {
  CheckboxAndLabel,
  Spinner,
  LabeledTextInput,
  LabeledSelect,
  LabeledTextarea,
  BlueButton,
} from "../../../common"
import NotificationModal from "../../../Modals/NotificationModal"
import {
  getStationById,
  updateStation,
  editStation,
  editLockers,
  cleanStationForm,
  closeNotificationSuccessed,
  closeNotificationFailture,
  showStationLoading,
  fetchLockerTypeSelectOptions,
  fetchRoutes,
  fetchCities,
  getPostalCode,
  closeNotificationPostalCodeError,
  closeNotificationPostalCodeInvalid,
  fetchLockerSizes,
  getAllRouteAreaCodes,
  isAreaCodeCanBeLinkedToStation,
  clearAreaCodeCanBeLinked,
} from "../../../../actions"

import { fetchIfEmpty } from "../../../../utils"
import "./style.scss"

class EditStation extends Component {
  constructor(props) {
    super(props)
    this.handleNotificationSuccessClick =
      this.handleNotificationSuccessClick.bind(this)
    this.handleNotificationFailtureClick =
      this.handleNotificationFailtureClick.bind(this)
    this.handlePostalCodeErrorClick = this.handlePostalCodeErrorClick.bind(this)
    this.handlePostalCodeInvalidClick =
      this.handlePostalCodeInvalidClick.bind(this)

    this.INIT_STATE = {
      routes: this.props.routesList,
      showReturnPackageError: false,
      selectedRouteId: null,
      formatedRouteAreacCodes: [],
      showAreaCodeCanBeLinkedMsg: false,
      selectedAreaCode: null,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.props.cleanStationForm()
    fetchIfEmpty(
      this.props.lockerTypes,
      this.props.fetchLockerTypeSelectOptions
    )

    fetchIfEmpty(this.props.routesList, this.props.fetchRoutes)
    fetchIfEmpty(this.props.citiesList, this.props.fetchCities)
    fetchIfEmpty(this.props.lockersSizes, this.props.fetchLockerSizes)
    this.props.getAllRouteAreaCodes()
    this.props.showStationLoading()
    this.props.getStationById(this.props.station.stationId)

    const emptyRow = { value: null, label: "ללא מסלול" }
    this.setState({
      routes: [...this.state.routes, emptyRow],
    })
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    const { unauthorized, history, station } = this.props
    if (unauthorized !== prevProps.unauthorized && unauthorized) {
      history.push("/")
    }
    if (
      this.props.routeAreaCodes.length > 0 &&
      this.state.formatedRouteAreacCodes.length === 0
    ) {
      const formatedRouteAreaCodes = this.formatRouteAreaCodesObject(
        this.props.routeAreaCodes
      )
      this.setState({ formatedRouteAreacCodes: formatedRouteAreaCodes })
    }
    if (station !== prevProps.station) {
      this.setRouteAreaCodesByRouteId(station.routeId)
      this.setState({ showAreaCodeCanBeLinkedMsg: false })
      this.props.clearAreaCodeCanBeLinked()
      if (this.state.formatedRouteAreacCodes.length > 0) {
        this.findValueForRouteAreaCodeId()
      }
    }
  }

  paymentVersionList = [
    { value: 1, label: "דור ראשון" },
    { value: 2, label: "דור שני" },
    { value: 3, label: "Nayax" },
    { value: 4, label: "אפליקציית מובייל" },
    { value: 5, label: "Hybrid" },
    { value: 6, label: "Pudo" },
  ]

  setRouteAreaCodesByRouteId(routeId) {
    const { routeAreaCodes } = this.props

    const filteredObjects = routeAreaCodes.filter(
      (obj) => obj.routeId === routeId
    )
    const formatedRouteAreacCodes =
      this.formatRouteAreaCodesObject(filteredObjects)

    this.setState({ formatedRouteAreacCodes })
  }

  handleStationChange(inputName, inputValue) {
    const {
      station,
      isAreaCodeCanBeLinkedToStation,
      readLockersLayout,
      editStation,
    } = this.props

    let _station = { ...station, [inputName]: inputValue }

    switch (inputName) {
      case "routeAreaCodeId":
        isAreaCodeCanBeLinkedToStation(inputValue)
        break

      case "routeId":
        this.setState({ selectedRouteId: inputValue })
        this.setRouteAreaCodesByRouteId(inputValue)
        break

      case "isLaundryActive":
        const isLaundryActive = inputValue === "true" || inputValue === true
        _station.minimumLaundryEmptyLockers = isLaundryActive
          ? _station.minimumLaundryEmptyLockers || 2
          : 0
        break

      case "isDecathlon":
      case "isAmshat":
      case "isYadMordechai":
      case "isBerorHayil":
      case "isSdeMoshe":
      case "isAmirim":
      case "isApple":
      case "isTAU":
      case "isMaayanZvi":
      case "isNitzanim":
        _station = this.resetStationFlags(
          inputValue ? inputName : null,
          _station
        )
        break

      case "layoutFile":
        readLockersLayout(inputValue)
        break

      default:
        break
    }

    _station = this.validateFields(_station)
    editStation(_station)
  }

  resetStationFlags(activeFlag, station) {
    const flags = [
      "isDecathlon",
      "isAmshat",
      "isYadMordechai",
      "isBerorHayil",
      "isSdeMoshe",
      "isAmirim",
      "isApple",
      "isTAU",
      "isMaayanZvi",
      "isNitzanim",
    ]

    flags.forEach((flag) => {
      station[flag] = flag === activeFlag
    })
    if (!activeFlag) {
      flags.forEach((flag) => {
        station[flag] = false
      })
    }
    return station
  }

  handleLockersChange(editedLocker, lockerIndex) {
    const { stationLockers } = this.props
    let _lockers = stationLockers.map((locker, index) =>
      index === lockerIndex ? editedLocker : locker
    )
    this.props.editLockers(_lockers)
  }

  isReturnPackagesValid(station) {
    const {
      isReturnPackageActive,
      postalCode,
      cityId,
      branchName_en,
      branchAddress_en,
    } = station
    const { selectedAreaCode } = this.state

    if (isReturnPackageActive) {
      if (
        !postalCode ||
        !cityId ||
        !branchName_en ||
        !branchAddress_en ||
        !selectedAreaCode ||
        selectedAreaCode === undefined
      ) {
        this.setState({ showReturnPackageError: true })
        return false
      }
    }
    return true
  }

  validateFields(_station) {
    if (_station.minimumLaundryEmptyLockers === "") {
      _station.minimumLaundryEmptyLockers = 0
    }

    return _station
  }

  handleSubmitButtonClick(station, event) {
    event.preventDefault()
    if (this.props.isAreaCodeCanBeLinked == false) {
      this.setState({ showAreaCodeCanBeLinkedMsg: true })
      return
    }
    let isReturnValid = this.isReturnPackagesValid(station)
    if (!isReturnValid) {
      return
    }
    this.props.showStationLoading()
    station = this.validateFields(station)

    this.props.updateStation(station)
  }

  handleNotificationSuccessClick() {
    this.props.closeNotificationSuccessed()
    this.props.history.goBack()
  }

  handleNotificationFailtureClick() {
    this.props.closeNotificationFailture()
  }
  handlePostalCodeErrorClick() {
    this.props.closeNotificationPostalCodeError()
  }
  handlePostalCodeInvalidClick() {
    this.props.closeNotificationPostalCodeInvalid()
  }
  handleIsAreaCodeCanBeLinked() {
    this.setState({ showAreaCodeCanBeLinkedMsg: false })
  }

  handleOpenCoordinatesClick(latitude, longitude, event) {
    event.preventDefault()
    window.open(
      `https://www.google.com/maps/place/${latitude},${longitude}`,
      "_blank"
    )
  }

  formatRouteAreaCodesObject(arrayOfObjects) {
    return arrayOfObjects.map((obj) => ({
      label:
        obj.routeAreaCodeName +
        (obj.routeAreaCodeDesc ? " - " + obj.routeAreaCodeDesc : ""),
      value: obj.id,
      routeId: obj.routeId,
    }))
  }

  getPostalCodeFromCoordinates(latitude, longitude, event) {
    event.preventDefault()
    this.props.getPostalCode(latitude, longitude)
  }

  openVideoUrl(videoUrl, event) {
    event.preventDefault()
    window.open(videoUrl, "_blank")
  }

  findValueForRouteAreaCodeId = () => {
    let value = this.state.formatedRouteAreacCodes.find(
      (option) => option.value == this.props.station?.routeAreaCodeId
    )
    this.setState({ selectedAreaCode: value })
  }

  render() {
    const {
      priceLists,
      stationLockers,
      showNotification_Success,
      showNotification_Faiture,
      isLoading,
      showPostalCodeError,
      showPostalCodeInvalid,
      routeAreaCodes,
    } = this.props

    const {
      showReturnPackageError,
      selectedRouteId,
      formatedRouteAreacCodes,
      showAreaCodeCanBeLinkedMsg,
    } = this.state

    if (isLoading) {
      return (
        <div className="station-editor-spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    const { stationNumber } = this.props.station

    if (showNotification_Success) {
      return (
        <NotificationModal
          show={showNotification_Success}
          title={" עדכון עמדה מס' " + stationNumber}
          text={"העמדה עודכנה בהצלחה"}
          onOkClick={this.handleNotificationSuccessClick}
        />
      )
    }
    if (showNotification_Faiture) {
      return (
        <NotificationModal
          show={showNotification_Faiture}
          title={" עדכון עמדה מס' " + stationNumber}
          text={"עדכון עמדה נכשל"}
          onOkClick={this.handleNotificationFailtureClick}
        />
      )
    }
    if (showPostalCodeError) {
      return (
        <NotificationModal
          show={showPostalCodeError}
          title={"יצירת מיקוד"}
          text={"כישלון ביצירת מיקוד"}
          onOkClick={this.handlePostalCodeErrorClick}
        />
      )
    }
    if (showPostalCodeInvalid) {
      return (
        <NotificationModal
          show={showPostalCodeInvalid}
          title={"יצירת מיקוד"}
          text={"מיקוד שקיבלת לא חוקי, אנא חפש ידני."}
          onOkClick={this.handlePostalCodeInvalidClick}
        />
      )
    }
    if (showReturnPackageError) {
      return (
        <NotificationModal
          show={showReturnPackageError}
          type={"warning"}
          title={"עדכון עמדה נכשל"}
          text={
            "חובה להכניס מיקוד, עיר, קוד איזור ,שם סניף באנגלית וכתובת סניף באנגלית לעמדות שתומכות בהחזרה"
          }
          onOkClick={() =>
            this.setState({
              showReturnPackageError: false,
            })
          }
        />
      )
    }
    if (showAreaCodeCanBeLinkedMsg == true) {
      return (
        <NotificationModal
          show={showAreaCodeCanBeLinkedMsg}
          type={"warning"}
          title={" מגבלה של קודי איזור עבור תחנה זו"}
          text={"אין אפשרות לקשר יותר קודי איזור לתחנה"}
          onOkClick={() => this.handleIsAreaCodeCanBeLinked()}
        />
      )
    }

    const {
      stationId,
      branchName,
      branchId,
      branchAddress,
      branchName_en,
      branchAddress_en,
      branchDisplayAddress,
      branchDisplayName,
      routeId,
      cityId,
      barStationNumber,
      isAlive,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      isDecathlon,
      minimumLaundryEmptyLockers,
      priceListId,
      latitude,
      longitude,
      availabilityHours,
      isDeliveryCompaniesActive,
      stationPaymentVersion,
      stationPriority,
      isAmot,
      isBLE,
      isYadMordechai,
      isSdeMoshe,
      isAmirim,
      isOutdoor,
      isVisible,
      nayaxTerminalSN,
      stationCodeBLE,
      postalCode,
      isReturnPackageActive,
      videoUrl,
      isReturnExelotActive,
      dhlStationNumber,
      dhlRouteCode,
      isApple,
      isTAU,
      routeAreaCodeId,
      isBerorHayil,
      storageSackId,
      nearByStationId,
      nearByStationId2,
      nearByStationId3,
      isAmshat,
      isMaayanZvi,
      isNitzanim,
    } = this.props.station

    const route =
      routeId && routeId !== ""
        ? this.state.routes.filter((r) => r.value === routeId)[0]
        : { value: null, label: "ללא מסלול" }
    const city =
      cityId && cityId !== ""
        ? this.props.citiesList.filter((r) => r.value === cityId)[0]
        : { value: null, label: "" }
    const nearByStation =
      nearByStationId && nearByStationId !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId
          )[0]
        : { value: null, label: "" }
    const nearByStation2 =
      nearByStationId2 && nearByStationId2 !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId2
          )[0]
        : { value: null, label: "" }
    const nearByStation3 =
      nearByStationId3 && nearByStationId3 !== ""
        ? this.props.stationsItemSelectList.filter(
            (r) => r.stationId === nearByStationId3
          )[0]
        : { value: null, label: "" }

    return (
      <div className="station-edit">
        <div className="header">
          <h1>עדכון עמדה</h1>
          <BlueButton
            extraClass={"btn-branch-card"}
            onClick={() =>
              this.props.history.push(
                `/StationsScreen/BranchCard?branchId=${branchId}`
              )
            }
          >
            לכרטיס סניף
          </BlueButton>
        </div>

        <div className="ss-container">
          <div className="stations-section-station-info">
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText="מס' עמדה"
                value={stationNumber}
                inputSize={6}
                onChange={(e) => {
                  this.handleStationChange("stationNumber", e.target.value)
                }}
              />
              <LabeledTextInput
                labelText="שם סניף"
                value={branchName}
                inputSize={30}
                onChange={(e) => {
                  this.handleStationChange("branchName", e.target.value)
                }}
              />
              <LabeledTextInput
                labelText="כתובת סניף"
                value={branchAddress}
                inputSize={50}
                onChange={(e) => {
                  this.handleStationChange("branchAddress", e.target.value)
                }}
              />
            </div>
            <div className="address-eng">
              <LabeledTextInput
                labelText="שם סניף  באנגלית (עם עיר בסוגריים)"
                value={branchName_en}
                inputSize={30}
                onChange={(e) => {
                  this.handleStationChange("branchName_en", e.target.value)
                }}
              />
              <LabeledTextInput
                labelText="כתובת סניף באנגלית (כתובת ללא עיר)"
                value={branchAddress_en}
                inputSize={50}
                maxlength={45}
                onChange={(e) => {
                  this.handleStationChange("branchAddress_en", e.target.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText="שם עמדה לסמס  (שם העמדה שישלח בסמס. אם אין ערך בשדה ישלח שם הסניף) (עם עיר בסוגריים)"
                value={branchDisplayName}
                inputSize={100}
                onChange={(e) => {
                  this.handleStationChange("branchDisplayName", e.target.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText="כתובת לסמס  (הכתובת שתשלח בסמס, אם אין ערך בשדה לא תשלח כתובת סמס) (כתובת ללא עיר)"
                value={branchDisplayAddress}
                inputSize={100}
                onChange={(e) => {
                  this.handleStationChange(
                    "branchDisplayAddress",
                    e.target.value
                  )
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <div className="container-select route-select">
                <div className="route-label ss-label">{"מסלול הפצה"}</div>
                <Select
                  classNamePrefix="base-select"
                  placeholder={"מסלול הפצה"}
                  value={route}
                  options={this.state.routes}
                  onChange={(e) => this.handleStationChange("routeId", e.value)}
                />
              </div>
            </div>
            <div className="stations-section-station-info-row">
              <div className="container-select area-code-select">
                <div className="route-label ss-label">{"קוד אזור מסלול"}</div>
                <Select
                  key={selectedRouteId}
                  classNamePrefix="base-select"
                  value={this.state.selectedAreaCode}
                  options={formatedRouteAreacCodes}
                  onChange={(e) =>
                    this.handleStationChange("routeAreaCodeId", e.value)
                  }
                />
                {!this.props.isAreaCodeCanBeLinked ? (
                  <div className="area-code-limit-msg">
                    חריגה מהמגבלה המותרת לעמדות הקשורות לקוד איזור זה.
                  </div>
                ) : null}
              </div>
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"מס' שק במחסן"}
                value={storageSackId ? storageSackId : "לא קיים"}
                disabled={true}
                inputSize={12}
              />
            </div>
            <div className="stations-section-station-info-row">
              <div className="container-select city-select">
                <div className="route-label ss-label">עיר</div>
                <Select
                  classNamePrefix="base-select"
                  placeholder={"עיר"}
                  value={city}
                  options={this.props.citiesList}
                  onChange={(e) => this.handleStationChange("cityId", e.value)}
                />
              </div>
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"מספר עמדה בבר הפצה"}
                value={barStationNumber}
                inputSize={12}
                onChange={(e) => {
                  this.handleStationChange("barStationNumber", e.target.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"קוד תחנה בDHL"}
                value={dhlStationNumber}
                inputSize={12}
                onChange={(e) => {
                  this.handleStationChange("dhlStationNumber", e.target.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"מספר קו בDHL"}
                value={dhlRouteCode}
                inputSize={12}
                onChange={(e) => {
                  this.handleStationChange("dhlRouteCode", e.target.value)
                }}
              />
            </div>

            <div className="checkbox-holder">
              <div className="chebox-container">
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isDeliveryActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isDeliveryActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה לקבלת חבילות?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isLaundryActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isLaundryActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה לשרותי כביסה?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isLockerRentActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isLockerRentActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה לשרותי השכרת תא?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isLockerToLockerDeliveryActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isLockerToLockerDeliveryActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה לשרותי משלוח בין תאים?"}
                  </CheckboxAndLabel>
                </div>
                <CheckboxAndLabel
                  checked={isDeliveryCompaniesActive}
                  onChange={(e) => {
                    this.handleStationChange(
                      "isDeliveryCompaniesActive",
                      e.target.checked
                    )
                  }}
                >
                  {"האם העמדה פתוחה לחברות השילוח?"}
                </CheckboxAndLabel>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isAmot}
                    onChange={(e) => {
                      this.handleStationChange("isAmot", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת בבניין אמות Amot?"}
                  </CheckboxAndLabel>
                </div>
                <div
                  key={stationPaymentVersion}
                  className="stations-section-station-info-row"
                >
                  <CheckboxAndLabel
                    checked={isBLE}
                    onChange={(e) => {
                      this.handleStationChange("isBLE", e.target.checked)
                    }}
                  >
                    {"האם העמדה BLE?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isOutdoor}
                    onChange={(e) => {
                      this.handleStationChange("isOutdoor", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת בחוץ?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isVisible}
                    onChange={(e) => {
                      this.handleStationChange("isVisible", e.target.checked)
                    }}
                  >
                    {"האם להציג באתר?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isReturnPackageActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isReturnPackageActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה להחזרות של בלדרים חיצוניים?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isReturnExelotActive}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isReturnExelotActive",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה זמינה להחזרות של נהגים שלנו?"}
                  </CheckboxAndLabel>
                </div>
              </div>
              <div className="chebox-container">
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isDecathlon}
                    onChange={(e) => {
                      this.handleStationChange("isDecathlon", e.target.checked)
                    }}
                  >
                    {"האם העמדה שייכת לדקטלון?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isYadMordechai}
                    onChange={(e) => {
                      this.handleStationChange(
                        "isYadMordechai",
                        e.target.checked
                      )
                    }}
                  >
                    {"האם העמדה נמצאת ביד מורדכי?"}
                  </CheckboxAndLabel>
                </div>

                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isBerorHayil}
                    onChange={(e) => {
                      this.handleStationChange("isBerorHayil", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת בברור חיל?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isTAU}
                    onChange={(e) => {
                      this.handleStationChange("isTAU", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת באוניברסיטת תל-אביב?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isSdeMoshe}
                    onChange={(e) => {
                      this.handleStationChange("isSdeMoshe", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת בשדה משה?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isAmirim}
                    onChange={(e) => {
                      this.handleStationChange("isAmirim", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת באמירים?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isApple}
                    onChange={(e) => {
                      this.handleStationChange("isApple", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת ב-Apple?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isAmshat}
                    onChange={(e) => {
                      this.handleStationChange("isAmshat", e.target.checked)
                    }}
                  >
                    {`האם העמדה שייכת לאמש"ט?`}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isMaayanZvi}
                    onChange={(e) => {
                      this.handleStationChange("isMaayanZvi", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת  במעיין צבי?"}
                  </CheckboxAndLabel>
                </div>
                <div className="stations-section-station-info-row">
                  <CheckboxAndLabel
                    checked={isNitzanim}
                    onChange={(e) => {
                      this.handleStationChange("isNitzanim", e.target.checked)
                    }}
                  >
                    {"האם העמדה נמצאת בניצנים?"}
                  </CheckboxAndLabel>
                </div>
              </div>
            </div>
            <div className="alt-stations-section">
              <div className="container-select">
                <div className="route-label ss-label">עמדה חילופית 1</div>
                <Select
                  classNamePrefix="base-select"
                  placeholder="בחר עמדה חילופית 1"
                  value={nearByStation}
                  options={this.props.stationsItemSelectList}
                  onChange={(e) =>
                    this.handleStationChange("nearByStationId", e.stationId)
                  }
                />
              </div>
              <div className="container-select">
                <div className="route-label ss-label">עמדה חילופית 2</div>
                <Select
                  classNamePrefix="base-select"
                  placeholder="בחר עמדה חילופית 2"
                  value={nearByStation2}
                  options={this.props.stationsItemSelectList}
                  onChange={(e) =>
                    this.handleStationChange("nearByStationId2", e.stationId)
                  }
                />
              </div>
              <div className="container-select">
                <div className="route-label ss-label">עמדה חילופית 3</div>
                <Select
                  classNamePrefix="base-select"
                  placeholder="בחר עמדה חילופית 3"
                  value={nearByStation3}
                  options={this.props.stationsItemSelectList}
                  onChange={(e) =>
                    this.handleStationChange("nearByStationId3", e.stationId)
                  }
                />
              </div>
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"כמות מינימלית של תאים פנויים עבור שירותי כביסה"}
                value={minimumLaundryEmptyLockers}
                type={"number"}
                maxsize="12"
                inputSize={12}
                onChange={(e) => {
                  this.handleStationChange(
                    "minimumLaundryEmptyLockers",
                    e.target.value
                  )
                }}
              />
            </div>
            <div className="stations-section-station-info">
              <LabeledTextInput
                labelText={"longitude"}
                value={longitude}
                type="number"
                maxsize="12"
                pattern="^[-,0-9]+${1-12}"
                onChange={(e) => {
                  this.handleStationChange("longitude", e.target.value)
                }}
              />
              <span className="comma-separator">,</span>
              <LabeledTextInput
                labelText={"latitude"}
                value={latitude}
                type="number"
                maxsize="12"
                pattern="^[-,0-9]+${1-12}"
                onChange={(e) => {
                  this.handleStationChange("latitude", e.target.value)
                }}
              />
              <button
                className="ss-button"
                onClick={(e) =>
                  this.handleOpenCoordinatesClick(latitude, longitude, e)
                }
              >
                {"פתח במפה"}
              </button>
            </div>
            <div className="textarea-container">
              <LabeledTextInput
                labelText="מיקוד"
                value={postalCode}
                type={"text"}
                pattern="\d*"
                maxLength="16"
                onChange={(e) => {
                  this.handleStationChange("postalCode", e.target.value)
                }}
              />
              <button
                className="ss-button"
                onClick={(e) =>
                  this.getPostalCodeFromCoordinates(latitude, longitude, e)
                }
              >
                חשב מיקוד
              </button>
            </div>
            <div className="video-url">
              <LabeledTextarea
                labelText={"סרטון כיצד למצוא את התחנה"}
                value={videoUrl}
                type={"text"}
                pattern="\d*"
                onChange={(e) => {
                  this.handleStationChange("videoUrl", e.target.value)
                }}
              />
              <button
                className="ss-button"
                onClick={(e) => this.openVideoUrl(videoUrl, e)}
              >
                פתח קישור
              </button>
            </div>
            <div className="textarea-container">
              <LabeledTextarea
                labelText={"שעות זמינות"}
                value={availabilityHours}
                inputSize={300}
                rows="3"
                onChange={(e) => {
                  this.handleStationChange("availabilityHours", e.target.value)
                }}
              />
            </div>
            <div className="container-select">
              <div
                key={stationPaymentVersion}
                className="payment-version-wrapper"
              >
                <div className="payment-version-label">{"גרסת תשלום"}</div>
                <Select
                  defaultValue={
                    stationPaymentVersion
                      ? this.paymentVersionList.find(
                          (o) => o.value === stationPaymentVersion
                        )
                      : { value: 2, label: "version 2" }
                  }
                  options={this.paymentVersionList}
                  onChange={(e) => {
                    this.handleStationChange("stationPaymentVersion", e.value)
                  }}
                />
              </div>
              <div className="stations-section-station-info-row">
                <LabeledTextInput
                  labelText={"מספר סידורי של Nayax Terminal"}
                  value={nayaxTerminalSN}
                  type={"text"}
                  pattern="\d*"
                  maxLength="16"
                  onChange={(e) => {
                    this.handleStationChange("nayaxTerminalSN", e.target.value)
                  }}
                />
              </div>
            </div>
            <div className="container-select">
              <LabeledSelect
                emptyOption={false}
                labelText={"עדיפות עמדה"}
                options={Array.from({ length: 21 }, (_, i) => i)}
                value={stationPriority}
                onChange={(e) => {
                  this.handleStationChange("stationPriority", e.target.value)
                }}
              />
            </div>
            <div className="stations-section-station-info-row">
              <LabeledTextInput
                labelText={"קוד לפתיחת לוקרים באפליקצית נהגים (BLE)"}
                value={stationCodeBLE}
                readOnly
                disabled
              />
            </div>
          </div>
          <div>
            <LabeledSelect
              labelText="מחירון"
              options={priceLists}
              value={priceListId}
              onChange={(e) => {
                this.handleStationChange("priceListId", e.target.value)
              }}
            />
          </div>
          <LockersList
            lockers={stationLockers}
            onLockersChange={this.handleLockersChange.bind(this)}
          />

          <button
            className="ss-button"
            onClick={(e) => {
              this.handleSubmitButtonClick(
                {
                  stationId,
                  stationNumber,
                  branchName,
                  branchAddress,
                  branchName_en,
                  branchAddress_en,
                  branchDisplayAddress,
                  branchDisplayName,
                  barStationNumber,
                  isAlive,
                  isDeliveryActive,
                  isLaundryActive,
                  isLockerRentActive,
                  isLockerToLockerDeliveryActive,
                  minimumLaundryEmptyLockers,
                  priceListId,
                  stationLockers,
                  latitude,
                  longitude,
                  availabilityHours,
                  isDeliveryCompaniesActive,
                  stationPriority,
                  stationPaymentVersion,
                  isDecathlon,
                  routeId,
                  cityId,
                  isAmot,
                  isBLE,
                  isYadMordechai,
                  isSdeMoshe,
                  isAmirim,
                  isOutdoor,
                  isVisible,
                  nayaxTerminalSN,
                  postalCode,
                  isReturnPackageActive,
                  videoUrl,
                  isReturnExelotActive,
                  dhlStationNumber,
                  dhlRouteCode,
                  isApple,
                  isTAU,
                  routeAreaCodeId,
                  isBerorHayil,
                  storageSackId,
                  nearByStationId,
                  nearByStationId2,
                  nearByStationId3,
                  isAmshat,
                  isMaayanZvi,
                  isNitzanim,
                },
                e
              )
            }}
          >
            שמור
          </button>
        </div>
      </div>
    )
  }
}

const MapStateToProps = ({
  stationsSection,
  selectOptions,
  errors,
  routes,
  cities,
  routeAreaCode,
  stationsList,
}) => {
  const {
    station,
    stationLockers,
    showNotification_Success,
    showNotification_Faiture,
    isLoading,
    showPostalCodeError,
    showPostalCodeInvalid,
  } = stationsSection
  const { isAreaCodeCanBeLinked, stationsItemSelectList } = stationsList
  const { priceLists, lockersSizes } = selectOptions
  const { unauthorized } = errors
  const routesList = routes.routes
  const { citiesList } = cities
  const { routeAreaCodes } = routeAreaCode
  return {
    station,
    stationLockers,
    priceLists,
    showNotification_Success,
    showNotification_Faiture,
    isLoading,
    unauthorized,
    routesList,
    citiesList,
    showPostalCodeError,
    showPostalCodeInvalid,
    lockersSizes,
    routeAreaCodes,
    isAreaCodeCanBeLinked,
    stationsItemSelectList,
  }
}

export default connect(MapStateToProps, {
  getStationById,
  updateStation,
  editStation,
  editLockers,
  cleanStationForm,
  closeNotificationSuccessed,
  closeNotificationFailture,
  showStationLoading,
  fetchLockerTypeSelectOptions,
  fetchRoutes,
  fetchCities,
  getPostalCode,
  closeNotificationPostalCodeError,
  closeNotificationPostalCodeInvalid,
  fetchLockerSizes,
  getAllRouteAreaCodes,
  isAreaCodeCanBeLinkedToStation,
  clearAreaCodeCanBeLinked,
})(EditStation)
