import React from "react"
import { getFormatedDate, getStatusNameFromObj } from "../../../../utils"
import "./style.scss"

const OrdersHistoryTable = ({ records, orderStatuses }) => {
  return (
    <div className="orders-history-table">
      <>
        <table border="1">
          <thead>
            <tr>
              <th>מספר הזמנה</th>
              <th>מספר חבילה</th>
              <th>שם המבצע</th>
              <th>עמדה</th>
              <th>שם עמדה</th>
              <th>תאריך סטטוס</th>
              <th>סוג הזמנה</th>
              <th>סטטוס</th>
              <th>סוג חשבון</th>
              <th>שם מסלול</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record.id}>
                <td>{record.orderNumber}</td>
                <td>{record.packageNumber}</td>
                <td>{record.userName}</td>
                <td>{record.stationNumber}</td>
                <td>{record.stationName}</td>
                <td>{getFormatedDate(record.statusDate)}</td>
                <td>{record.orderTypeName}</td>
                <td>
                  {getStatusNameFromObj(orderStatuses, record.orderStatus)}
                </td>
                <td>{record.accountTypeName}</td>
                <td>{record.routeName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    </div>
  )
}

export default OrdersHistoryTable
