// USER SIGN IN
export const ON_USER_SIGN_IN_REQUEST = "ON_USER_SIGN_IN_REQUEST"
export const ON_USER_SIGN_IN_SUCCESS = "ON_USER_SIGN_IN_SUCCESS"
export const ON_USER_SIGN_IN_FAIL = "ON_USER_SIGN_IN_FAIL"
export const ON_USER_SIGN_IN_RESET_ATTEMPTS = "ON_USER_SIGN_IN_RESET_ATTEMPTS"
export const ON_USER_CCTOKEN_REMOVED_SUCCESS = "ON_USER_CCTOKEN_REMOVED_SUCCESS"
export const ON_USER_CCTOKEN_REMOVED_FAIL = "ON_USER_CCTOKEN_REMOVED_FAIL"
export const ON_CREATE_UPDATE_USER_EXISTING_ERROR =
  "ON_CREATE_UPDATE_USER_EXISTING_ERROR"
export const CLEAR_USER_EXISTING_ERROR = "CLEAR_USER_EXISTING_ERROR"

//USER UPDATE SELF PASSWORD
export const ON_UPDATE_SELF_PASSWORD_REQUEST = "ON_UPDATE_SELF_PASSWORD_REQUEST"
export const ON_UPDATE_SELF_PASSWORD_SUCCESS = "ON_UPDATE_SELF_PASSWORD_SUCCESS"
export const ON_UPDATE_SELF_PASSWORD_FAIL = "ON_UPDATE_SELF_PASSWORD_FAIL"

// GET ORDERS LIST
export const ON_GET_ORDER_BY_NUMBER_REQUEST = "ON_GET_ORDER_BY_NUMBER_REQUEST"
export const ON_GET_ORDER_BY_NUMBER_SUCCESS = "ON_GET_ORDER_BY_NUMBER_SUCCESS"
export const ON_GET_ORDER_BY_NUMBER_FAIL = "ON_GET_ORDER_BY_NUMBER_FAIL"
export const ON_GET_ORDERS_LIST_SUCCESS = "ON_GET_ORDERS_LIST_SUCCESS"
export const ON_GET_ORDERS_LIST_FAIL = "ON_GET_ORDERS_LIST_FAIL"
export const ON_GET_ORDER_DETAILS_REQUEST = "ON_GET_ORDER_DETAILS_REQUEST"
export const ON_GET_ORDER_DETAILS_SUCCESS = "ON_GET_ORDER_DETAILS_SUCCESS"
export const ON_GET_ORDER_DETAILS_FAIL = "ON_GET_ORDER_DETAILS_FAIL"
export const ON_ORDER_FILTER_BY_CHNAGE = "ON_ORDER_FILTER_BY_CHNAGE"
export const SET_EXTERNAL_ORDER_TO_EDIT = "SET_EXTERNAL_ORDER_TO_EDIT"
export const ON_FILTERED_ACTIVE_TYPES_UPDATE = "ON_FILTERED_ACTIVE_TYPES_UPDATE"
export const ON_FILTERED_ACTIVE_STATUSES_UPDATE =
  "ON_FILTERED_ACTIVE_STATUSES_UPDATE"
export const ON_FILTERED_ACTIVE_STATIONS_UPDATE =
  "ON_FILTERED_ACTIVE_STATIONS_UPDATE"
export const ON_FILTERED_IS_PAID_STATUS_UPDATE =
  "ON_FILTERED_IS_PAID_STATUS_UPDATE"
export const ON_ORDER_UPDATE_REQUEST = "ON_ORDER_UPDATE_REQUEST"
export const ON_ORDER_UPDATE_SUCCESS = "ON_ORDER_UPDATE_SUCCESS"
export const ON_ORDER_UPDATE_FAIL = "ON_ORDER_UPDATE_FAIL"

export const ON_ORDER_RECALC_LOADING = "ON_ORDER_RECALC_LOADING"
export const ON_ORDER_RECALC_NO_SAVE_SUCCESS = "ON_ORDER_RECALC_NO_SAVE_SUCCESS"
export const ON_ORDER_RECALC_NO_SAVE_FAIL = "ON_ORDER_RECALC_NO_SAVE_FAIL"
export const ON_CLEAR_RECALCULATED = "ON_CLEAR_RECALCULATED"
export const CLEAR_CALCULATE_ERROR = "CLEAR_CALCULATE_ERROR"

export const CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS =
  "CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS"
export const ON_ORDER_LOCAL_UPDATE = "ON_ORDER_LOCAL_UPDATE"
export const ON_CREATE_ORDER_SUCCESS = "ON_CREATE_ORDER_SUCCESS"
export const ON_CREATE_ORDER_FAIL = "ON_CREATE_ORDER_FAIL"
export const ON_CREATE_ORDER = "ON_CREATE_ORDER"
export const SEND_READY_IN_LOCKER_SMS_REQUEST =
  "SEND_READY_IN_LOCKER_SMS_REQUEST"
export const SEND_READY_IN_LOCKER_SMS_SUCCESS =
  "SEND_READY_IN_LOCKER_SMS_SUCCESS"
export const SEND_READY_IN_LOCKER_SMS_FAIL = "SEND_READY_IN_LOCKER_SMS_FAIL"
export const CLEAR_READY_IN_LOCKER_SMS = "CLEAR_READY_IN_LOCKER_SMS"
export const CLEAR_NEW_MAIL_ORDER = "CLEAR_NEW_MAIL_ORDER"
export const ON_CREATE_MAIL_ORDER_SUCCESS = "ON_CREATE_MAIL_ORDER_SUCCESS"
export const ON_CREATE_MAIL_ORDER_FAIL = "ON_CREATE_MAIL_ORDER_FAIL"
export const CLEAR_ORDER_DETAILS = "CLEAR_ORDER_DETAILS"
export const CLEAR_ORDERS_LIST = "CLEAR_ORDER_LIST"
export const ON_GET_ORDERS_BY_SUPPLIER_SUCCESS =
  "ON_GET_ORDERS_BY_SUPPLIER_SUCCESS"
export const ON_GET_ORDERS_BY_SUPPLIER_FAIL = "ON_GET_ORDERS_BY_SUPPLIER_FAIL"
export const ON_GET_ORDERS_BY_ROUTE_SUCCESS = "ON_GET_ORDERS_BY_ROUTE_SUCCESS"
export const ON_GET_ORDERS_BY_ROUTE_FAIL = "ON_GET_ORDERS_BY_ROUTE_FAIL"
export const CLEAR_ORDERS_BY_ROUTE = "CLEAR_ORDERS_BY_ROUTE"
export const ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS =
  "ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS"
export const ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL =
  "ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL"
export const SEND_DELAY_SMS_SUCCESS = "SEND_DELAY_SMS_SUCCESS"
export const SEND_DELAY_SMS_FAIL = "SEND_DELAY_SMS_FAIL"
export const ON_UPDATE_ORDER_BAGS_SUCCESS = "ON_UPDATE_ORDER_BAGS_SUCCESS"
export const ON_UPDATE_ORDER_BAGS_FAIL = "ON_UPDATE_ORDER_BAGS_FAIL"
export const CLEAR_ORDER_BAGS_STATUS = "CLEAR_ORDER_BAGS_STATUS"

export const ON_GET_CHEAP_SIM_ORDERS_SUCCESS = "ON_GET_CHEAP_SIM_ORDERS_SUCCESS"
export const ON_GET_CHEAP_SIM_ORDERS_FAIL = "ON_GET_CHEAP_SIM_ORDERS_FAIL"
export const ON_ORDER_CHEAPSIM_UPDATE_SUCCESS =
  "ON_ORDER_CHEAPSIM_UPDATE_SUCCESS"
export const ON_ORDER_CHEAPSIM_UPDATE_FAIL = "ON_ORDER_CHEAPSIM_UPDATE_FAIL"
export const CLEAR_CHEAPSIM_UPDATE = "CLEAR_CHEAPSIM_UPDATE"
export const CLEAR_CHEAPSIM_LIST = "CLEAR_CHEAPSIM_LIST"

export const CLEAR_NEW_LOCKER_TO_LOCKER_ORDER =
  "CLEAR_NEW_LOCKER_TO_LOCKER_ORDER"
export const ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS =
  "ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS"
export const ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL =
  "ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL"
export const ON_GET_RECEPTION_ORDERS_SUCCESS = "ON_GET_RECEPTION_ORDERS_SUCCESS"
export const ON_GET_RECEPTION_ORDERS_FAIL = "ON_GET_RECEPTION_ORDERS_FAIL"
export const ON_RECEPTION_ORDER_PROCESS_SUCCESS =
  "ON_RECEPTION_ORDER_UPDATE_SUCCESS"
export const ON_RECEPTION_ORDER_PROCESS_FAIL = "ON_RECEPTION_ORDER_UPDATE_FAIL"
export const ON_RECEPTION_ORDERS_LOADING = "ON_RECEPTION_ORDERS_LOADING"
export const ON_RECEPTION_ORDER_NOT_ALLOWED = "ON_RECEPTION_ORDER_NOT_ALLOWED"
export const CLEAR_RECEPTION_ORDER_NOT_ALLOWED =
  "CLEAR_RECEPTION_ORDER_NOT_ALLOWED"
export const ON_PROCESS_ORDER_LOADING = "ON_UPDATE_ORDER_LOADING"
export const CLEAR_RECEPTION_ORDERS = "CLEAR_RECEPTION_ORDERS"
export const ON_END_OF_DAY_SUCCESS = "ON_END_OF_DAY_SUCCESS"
export const ON_END_OF_DAY_FAIL = "ON_END_OF_DAY_FAIL"
export const ON_END_OF_DAY_LOADING = "ON_END_OF_DAY_LOADING"
export const CLEAR_END_OF_DAY = "CLEAR_END_OF_DAY"
export const CLEAR_CERTIFICATES = "CLEAR_CERTIFICATES"
export const ON_SHIPPING_CERTIFICATE_LOADING = "ON_SHIPPING_CERTIFICATE_LOADING"
export const ON_GET_SHIPPING_CERTIFICATE_SUCCESS =
  "ON_GET_SHIPPING_CERTIFICATE_SUCCESS"
export const ON_GET_SHIPPING_CERTIFICATE_FAIL =
  "ON_GET_SHIPPING_CERTIFICATE_FAIL"
export const ON_LOAD_NEW_MAIL_ORDER = "ON_LOAD_NEW_MAIL_ORDER"
export const ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING =
  "ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING"
export const ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS =
  "ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS"
export const ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL =
  "ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL"
export const ON_ROUTE_SHIPPING_CERTIFICATE_LOADING =
  "ON_ROUTE_SHIPPING_CERTIFICATE_LOADING"
export const ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS =
  "ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS"
export const ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL =
  "ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL"

export const ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST =
  "ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST"
export const ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS =
  "ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS"
export const ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL =
  "ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL"
export const CLEAR_ORDER_BY_PACKAGE_OR_NUMBER =
  "CLEAR_ORDER_BY_PACKAGE_OR_NUMBER"
export const CLEAR_GET_ORDER_BY_NUMBER = "CLEAR_GET_ORDER_BY_NUMBER"

export const ON_RETURN_TO_SENDER_SUCCESS = "ON_RETURN_TO_SENDER_SUCCESS"
export const ON_RETURN_TO_SENDER_FAIL = "ON_RETURN_TO_SENDER_FAIL"

//SEARCH ORDERS
export const ON_GET_SEARCHED_ORDERS_SUCCESS = "ON_GET_SEARCHED_ORDERS_SUCCESS"
export const ON_GET_SEARCHED_ORDERS_FAIL = "ON_GET_SEARCHED_ORDERS_FAIL"
export const ON_ORDERS_LOADING = "ON_ORDERS_LOADING"
export const SET_SEARCH_PAGE_STEP = "SET_SEARCH_PAGE_STEP"
export const SET_ORDERS_SEARCH = "SET_ORDERS_SEARCH"
export const ON_GET_READY_TO_LOAD_ORDERS_SUCCESS =
  "ON_GET_READY_TO_LOAD_ORDERS_SUCCESS"
export const ON_GET_READY_TO_LOAD_ORDERS_FAIL =
  "ON_GET_READY_TO_LOAD_ORDERS_FAIL"
export const ON_LOADING_READY_ORDERS = "ON_LOADING_READY_ORDERS"
export const CLEAR_ORDERS_SEARCH = "CLEAR_ORDERS_SEARCH"
export const ON_GET_SEARCHED_ORDERS_LOADING = "ON_GET_SEARCHED_ORDERS_LOADING"

// GET USERS LIST
export const ON_GET_USERS_LIST_SUCCESS = "ON_GET_USERS_LIST_SUCCESS"
export const ON_GET_USERS_LIST_FAIL = "ON_GET_USERS_LIST_FAIL"
export const ON_GET_USER_DETAILS_SUCCESS = "ON_GET_USER_DETAILS_SUCCESS"
export const ON_GET_USER_DETAILS_FAIL = "ON_GET_USER_DETAILS_FAIL"
export const ON_USERS_LOADING = "ON_USERS_LOADING"
export const ON_USERS_SEARCH_NOT_VALID_INPUT = "ON_USERS_SEARCH_NOT_VALID_INPUT"
export const REMOVE_NOT_VALID_INPUT = "REMOVE_NOT_VALID_INPUT"

//SEARCH USERS
export const SET_USERS_SEARCH = "SET_USERS_SEARCH"
export const ON_USERS_SEARCH_SUCCESS = "ON_USERS_SEARCH_SUCCESS"
export const CLEAR_USER_SEARCH = "CLEAR_USER_SEARCH"

// GET ORDER STATUSES
export const ON_GET_ORDERS_STATUSES_SUCSESS = "ON_GET_ORDERS_STATUSES_SUCSESS"
export const ON_GET_ORDERS_STATUSES_FAIL = "ON_GET_ORDERS_STATUSES_FAIL"
export const CLEAR_ORDER_STATUSES = "CLEAR_ORDER_STATUSES"
export const ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS =
  "ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS"
export const ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL =
  "ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL"

// GET LOCKERS LIST
export const GET_STATION_LOCKERS_SUCCESS = "GET_STATION_LOCKERS_SUCCESS"
export const GET_STATION_LOCKERS_FAIL = "GET_STATION_LOCKERS_FAIL"
export const ON_START_LOCKERS_LOADING = "ON_START_LOCKERS_LOADING"
export const SET_LOCKERS_STATION = "SET_LOCKERS_STATION"
export const UPDATE_LOCKER_STATUS_SUCCESS = "UPDATE_LOCKER_STATUS_SUCCESS"
export const UPDATE_LOCKER_STATUS_FAIL = "UPDATE_LOCKER_STATUS_FAIL"
export const UPDATE_LOCKER_STATUS_LOADING = "UPDATE_LOCKER_STATUS_LOADING"
export const RESET_UPDATE_LOCKER_STATUS_ERROR =
  "RESET_UPDATE_LOCKER_STATUS_ERROR"
export const UPDATE_LOCKER_SIZE_SUCCESS = "UPDATE_LOCKER_SIZE_SUCCESS"
export const UPDATE_LOCKER_SIZE_FAIL = "UPDATE_LOCKER_SIZE_FAIL"

// LOCKERS HISTORY
export const SET_LOCKERS_HISTORY_STATION = "SET_LOCKERS_HISTORY_STATION"
export const ON_LOADING_LOCKERS_HISTORY = "ON_LOADING_LOCKERS_HISTORY"

export const GET_SEARCHED_LOCKERS_HISTORY_SUCCESS =
  "GET_SEARCHED_LOCKERS_HISTORY_SUCCESS"
export const GET_SEARCHED_LOCKERS_HISTORY_FAIL =
  "GET_SEARCHED_LOCKERS_HISTORY_FAIL"
export const SET_LOCKERS_HISTORY_SEARCH = "SET_LOCKERS_HISTORY_SEARCH"

export const GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS =
  "GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS"
export const GET_SEARCHED_LOCKERS_BY_STATION_FAIL =
  "GET_SEARCHED_LOCKERS_BY_STATION_FAIL"

// SERVICES
export const ON_GET_SERVICES_SUCCESS = "ON_GET_SERVICES_SUCCESS"
export const ON_GET_SERVICES_FAILURE = "ON_GET_SERVICES_FAILURE"

// PROCESSES
export const ON_GET_PROCESSES_SUCCESS = "ON_GET_PROCESSES_SUCCESS"
export const ON_GET_PROCESSES_FAILURE = "ON_GET_PROCESSES_FAILURE"
export const ON_PROCESS_IMAGE_UPLOAD_SUCCESS = "ON_PROCESS_IMAGE_UPLOAD_SUCCESS"
export const ON_PROCESS_IMAGE_UPLOAD_FAILURE = "ON_PROCESS_IMAGE_UPLOAD_FAILURE"
export const ON_PROCESS_UPDATE_SUCCESS = "ON_PROCESS_UPDATE_SUCCESS"
export const ON_PROCESS_UPDATE_FAILURE = "ON_PROCESS_UPDATE_FAILURE"
export const ON_PROCESS_DELETE_SUCCESS = "ON_PROCESS_DELETE_SUCCESS"
export const ON_PROCESS_DELETE_FAILURE = "ON_PROCESS_DELETE_FAILURE"

// ITEMS
export const ON_GET_ITEMS_SUCCESS = "ON_GET_ITEMS_SUCCESS"
export const ON_GET_ITEMS_FAILURE = "ON_GET_ITEMS_FAILURE"
export const ON_ITEM_UPDATE_SUCCESS = "ON_ITEM_UPDATE_SUCCESS"
export const ON_ITEM_UPDATE_FAILURE = "ON_ITEM_UPDATE_FAILURE"
export const ON_ITEM_DELETE_SUCCESS = "ON_ITEM_DELETE_SUCCESS"
export const ON_ITEM_DELETE_FAILURE = "ON_ITEM_DELETE_FAILURE"
export const ON_ITEM_CREATE_SUCCESS = "ON_ITEM_CREATE_SUCCESS"
export const ON_ITEM_CREATE_FAILURE = "ON_ITEM_CREATE_FAILURE"
export const CLEAR_ITEM_STATUS = "CLEAR_ITEM_STATUS"

// PRICE LISTS
export const SELECT_PRICE_LIST = "SELECT_PRICE_LIST"
export const ON_GET_PRICE_LIST_SUCCESS = "ON_GET_PRICE_LIST_SUCCESS"
export const ON_GET_PRICE_LIST_FAILURE = "ON_GET_PRICE_LIST_FAILURE"
export const ON_PRICE_LIST_DELETE_SUCCESS = "ON_PRICE_LIST_DELETE_SUCCESS"
export const ON_PRICE_LIST_DELETE_FAILURE = "ON_PRICE_LIST_DELETE_FAILURE"
export const ON_PRICE_LIST_ITEM_DELETE_SUCCESS =
  "ON_PRICE_LIST_ITEM_DELETE_SUCCESS"
export const ON_PRICE_LIST_ITEM_DELETE_FAILURE =
  "ON_PRICE_LIST_ITEM_DELETE_FAILURE"
export const ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_SUCCESS =
  "ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_SUCCESS"
export const ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_FAILURE =
  "ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_FAILURE"
export const ON_UPDATE_PRICE_LIST_ITEM_SUCCESS =
  "ON_UPDATE_PRICE_LIST_ITEM_SUCCESS"
export const ON_UPDATE_PRICE_LIST_ITEM_FAILURE =
  "ON_UPDATE_PRICE_LIST_ITEM_FAILURE"
export const ON_CREATE_PRICE_LIST_ITEM_SUCCESS =
  "ON_CREATE_PRICE_LIST_ITEM_SUCCESS"
export const ON_CREATE_PRICE_LIST_ITEM_FAILURE =
  "ON_CREATE_PRICE_LIST_ITEM_FAILURE"
// GET SELECT OPTIONS (Fetch the options for selects like: stations list, lockers statuses list, orders statuses list)
export const ON_GET_STATION_SELECT_OPTIONS_SUCCESS =
  "ON_GET_STATION_SELECT_OPTIONS_SUCCESS"
export const ON_GET_STATION_SELECT_OPTIONS_FAILURE =
  "ON_GET_STATION_SELECT_OPTIONS_FAILURE"
export const ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS =
  "ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS"
export const ON_GET_LOCKER_STATUS_SELECT_OPTIONS_FAILURE =
  "ON_GET_LOCKER_STATUS_SELECT_OPTIONS_FAILURE"
export const ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS =
  "ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS"
export const ON_GET_LOCKER_TYPE_SELECT_OPTIONS_FAILURE =
  "ON_GET_LOCKER_TYPE_SELECT_OPTIONS_FAILURE"
export const ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS =
  "ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS"
export const ON_GET_PRICE_LIST_SELECT_OPTIONS_FAILURE =
  "ON_GET_PRICE_LIST_SELECT_OPTIONS_FAILURE"
export const ON_GET_USER_ROLES_SUCCESS = "ON_GET_USER_ROLES_SUCCESS"
export const ON_GET_USER_ROLES_FAILURE = "ON_GET_USER_ROLES_FAILURE"
export const ON_GET_ORDER_TYPES_SUCCESS = "ON_GET_ORDER_TYPES_SUCCESS"
export const ON_GET_ORDER_TYPES_FAILURE = "ON_GET_ORDER_TYPES_FAILURE"
export const ON_GET_USER_SOURCES_SUCCESS = "ON_GET_USER_SOURCES_SUCCESS"
export const ON_GET_USER_SOURCES_FAILURE = "ON_GET_USER_SOURCES_FAILURE"
export const ON_GET_ACCOUNT_TYPES_SUCCESS = "ON_GET_ACCOUNT_TYPES_SUCCESS"
export const ON_GET_ACCOUNT_TYPES_FAILURE = "ON_GET_ACCOUNT_TYPES_FAILURE"
export const ON_GET_LOCKERS_SIZES_SUCCESS = "ON_GET_LOCKERS_SIZES_SUCCESS"
export const ON_GET_LOCKERS_SIZES_FAILURE = "ON_GET_LOCKERS_SIZES_FAILURE"
export const ON_GET_SUPPLIER_PRICES_SUCCESS = "ON_GET_SUPPLIER_PRICES_SUCCESS"
export const ON_GET_SUPPLIER_PRICES_FAILURE = "ON_GET_SUPPLIER_PRICES_FAILURE"
export const ON_SUPPLIER_PRICE_UPDATE_SUCCESS =
  "ON_SUPPLIER_PRICE_UPDATE_SUCCESS"
export const ON_SUPPLIER_PRICE_UPDATE_FAILURE =
  "ON_SUPPLIER_PRICE_UPDATE_FAILURE"
export const CLEAR_SUPPLIER_PRICE = "CLEAR_SUPPLIER_PRICE"

// STATIONS ACTIONS
export const ON_GET_STATIONS_SUCCESS = "ON_GET_STATIONS_SUCCESS"
export const ON_GET_STATIONS_PROGRESS = "ON_GET_STATIONS_PROGRESS"
export const ON_GET_STATIONS_FAILURE = "ON_GET_STATIONS_FAILURE"
export const ON_SELECT_STATION = "ON_SELECT_STATION"
export const ON_CLOSE_STATION_FORM = "ON_CLOSE_STATION_FORM"
export const ON_LEAVE_STATIONS_SECTION = "ON_LEAVE_STATIONS_SECTION"
export const ON_EDIT_STATION = "ON_EDIT_STATION"
export const ON_UPDATE_STATION_SUCCESS = "ON_UPDATE_STATION_SUCCESS"
export const ON_UPDATE_STATION_FAILURE = "ON_UPDATE_STATION_FAILURE"
export const ON_CREATE_STATION_SUCCESS = "ON_CREATE_STATION_SUCCESS"
export const ON_CREATE_STATION_PROGRESS = "ON_CREATE_STATION_PROGRESS"
export const ON_CREATE_STATION_FAILURE = "ON_CREATE_STATION_FAILURE"
export const SEND_STATION_SMS_SUCCESS = "SEND_STATION_SMS_SUCCESS"
export const SEND_STATION_SMS_FAIL = "SEND_STATION_SMS_FAIL"
export const CLEAR_STATION_SMS = "CLEAR_STATION_SMS"
export const GET_POSTAL_CODE_SUCCESS = "GET_POSTAL_CODE_SUCCESS"
export const GET_POSTAL_CODE_FAIL = "GET_POSTAL_CODE_FAIL"

export const ON_CLOSE_STATION_NOTIFICATION_SUCCESSED =
  "ON_CLOSE_STATION_NOTIFICATION_SUCCESSED"
export const ON_CLOSE_STATION_NOTIFICATION_FAILTURE =
  "ON_CLOSE_STATION_NOTIFICATION_FAILTURE"
export const ON_STATION_SHOW_LOADING = "ON_STATION_SHOW_LOADING"
export const IS_AREA_CODE_CAN_BE_LINKED = "IS_AREA_CODE_CAN_BE_LINKED"
export const CLEAR_AREA_CODE_CAN_BE_LINKED = "CLEAR_AREA_CODE_CAN_BE_LINKED"

//STATIONS SEARCH
export const ON_STATIONS_FILTER_BY_TEXT = "ON_STATIONS_FILTER_BY_TEXT"
export const ON_STATIONS_FILTER_BY_PAYMENT_VERSION =
  "ON_STATIONS_FILTER_BY_PAYMENT_VERSION"
export const ON_STATIONS_FILTER_BY_STATION = "ON_STATIONS_FILTER_BY_STATION"
export const CLEAR_LOCAL_STATIONS_FILTER_SEARCH =
  "CLEAR_LOCAL_STATIONS_FILTER_SEARCH"
export const GET_STATION_BY_ID_SUCCESS = "GET_STATION_BY_ID_SUCCESS"
export const GET_STATION_BY_ID_FAIL = "GET_STATION_BY_ID_FAIL"
export const ON_STATIONS_SET_PAGE = "ON_STATIONS_SET_PAGE"
// LOCKERS ACTIONS
export const ON_READ_LOCKERS_LAYOUT_SUCCESS = "ON_READ_LOCKERS_LAYOUT_SUCCESS"
export const ON_READ_LOCKERS_LAYOUT_FAILURE = "ON_READ_LOCKERS_LAYOUT_FAILURE"
export const ON_LOCKER_CHANGE = "ON_LOCKER_CHANGE"
export const ON_EDIT_LOCKERS = "ON_EDIT_LOCKERS"
export const ON_STATIONS_FILTER_BY_IS_ALIVE = "ON_STATIONS_FILTER_BY_IS_ALIVE"
export const ON_STATIONS_FILTER_BY_IS_VISIBLE =
  "ON_STATIONS_FILTER_BY_IS_VISIBLE"
export const ON_STATIONS_FILTER_BY_ROUTE = "ON_STATIONS_FILTER_BY_ROUTE"
export const ON_STATIONS_FILTER_BY_AREA_CODE = "ON_STATIONS_FILTER_BY_AREA_CODE"

// REPORTS ACTIONS
export const ON_DOWNLOAD_DETAILED_LOCKERS_REPORT_SUCCESS =
  "ON_DOWNLOAD_DETAILED_LOCKERS_REPORT_SUCCESS"
export const ON_DOWNLOAD_DETAILED_LOCKERS_REPORT_FAILURE =
  "ON_DOWNLOAD_DETAILED_LOCKERS_REPORT_FAILURE"
export const ON_BALANCE_FETCH_START = "ON_BALANCE_FETCH_START"
export const ON_BALANCE_FETCH_SUCCESS = "ON_BALANCE_FETCH_SUCCESS"

// EDITOR
export const ON_GET_TEXT_MESSAGES_SUCCESS = "ON_GET_TEXT_MESSAGES_SUCCESS"
export const ON_GET_TEXT_MESSAGES_FAILURE = "ON_GET_TEXT_MESSAGES_FAILURE"
export const ON_PICK_TEXT_MESSAGE = "ON_PICK_TEXT_MESSAGE"

// IMPORT
export const ON_ORDERS_IMPORT_SUCCESS = "ON_ORDERS_IMPORT_SUCCESS"
export const ON_USERS_IMPORT_SUCCESS = "ON_USERS_IMPORT_SUCCESS"
export const ON_PRICE_LIST_IMPORT_SUCCESS = "ON_PRICE_LIST_IMPORT_SUCCESS"

// ERROR ACTIONS
export const ON_ERROR_MODAL_CLOSE = "ON_ERROR_MODAL_CLOSE"
export const ON_401_UNAOUTHORIZED_REQUEST_ERROR =
  "ON_401_UNAOUTHORIZED_REQUEST_ERROR"
export const ON_NO_RESPONSE_RECEIVED_ERROR = "ON_NO_RESPONSE_RECEIVED_ERROR"
export const ON_UNHANDLED_REQUEST_ERROR = "ON_UNHANDLED_REQUEST_ERROR"
export const ON_UNHANDLED_INTERNAL_SERVER_ERROR =
  "ON_UNHANDLED_INTERNAL_SERVER_ERROR"

// CLEAN-UP ACTIONS
export const ON__UNMOUNT = "ON__UNMOUNT"

// ON CHANGE SEARCH
export const ON_SEARCH_DATA_CHANGE = "ON_SEARCH_DATA_CHANGE"

// STATIONS ACTIONS
export const FETCH_ALL_STATIONS_DATA = "FETCH_ALL_STATIONS_DATA"
export const STATION_PRICE_LIST_CLEAR = "STATION_PRICE_LIST_CLEAR"
export const FETCH_STATION_PRICE_LIST_EN = "FETCH_STATION_PRICE_LIST_EN"
export const FETCH_STATION_PRICE_LIST_HE = "FETCH_STATION_PRICE_LIST_HE"
export const ON_CLOSE_POSTAL_NOTIFICATION_ERROR =
  "ON_CLOSE_POSTAL_NOTIFICATION_ERROR"

// FIREBASE REAL TIME DASHBOARD
export const RT_DASHBOARD_EVENTS_ARRAY_READY = "RT_DASHBOARD_EVENTS_ARRAY_READY"

// CREATE USER ACTIONS
export const ON_CREATE_UPDATE_USER_REQUEST = "ON_CREATE_UPDATE_USER_REQUEST"
export const ON_CREATE_USER_SUCCESS = "ON_CREATE_USER_SUCCESS"
export const ON_UPDATE_USER_SUCCESS = "ON_UPDATE_USER_SUCCESS"
// export const ON_CREATE_USER_FAILURE = 'ON_CREATE_USER_FAILURE';
export const ON_CREATE_UPDATE_USER_ERROR = "ON_CREATE_UPDATE_USER_ERROR"
export const ON_USER_DATA_FIELD_UPDATE = "ON_USER_DATA_FIELD_UPDATE"
export const FETCH_EXISTING_USER_DATA = "FETCH_EXISTING_USER_DATA"
export const CLEAR_CURRENT_CREATE_EDIT_USER_DATA =
  "CLEAR_CURRENT_CREATE_EDIT_USER_DATA"
export const ON_SET_USER_ID = "ON_SET_USER_ID"
export const ON_GET_COUNTRIES_SUCCESS = "ON_GET_COUNTRIES_SUCCESS"
export const ON_GET_LANGUAGE_SUCCESS = "ON_GET_LANGUAGE_SUCCESS"

// UPDATE USER ACTIONS
// export const ON_UPDATE_USER_SUCCESS = 'ON_UPDATE_USER_SUCCESS';
// export const ON_UPDATE_USER_FAILURE = 'ON_UPDATE_USER_FAILURE';

export const FETCH_STATION_NOTIFICATIONS = "FETCH_STATION_NOTIFICATIONS"

//COUPONS ACTIONS
export const FETCH_COUPONS = "FETCH_COUPONS"
export const FETCH_ACTIVE_COUPONS = "FETCH_ACTIVE_COUPONS"
export const FETCH_COUPON = "FETCH_COUPON"
export const ON_CREATE_COUPON = "ON_CREATE_COUPON"
export const ON_UPDATE_COUPON = "ON_UPDATE_COUPON"
export const ON_REMOVE_COUPON = "ON_REMOVE_COUPON"
export const CLEAN_COUPON_FORM = "CLEAN_COUPON_FORM"
export const EDIT_COUPON_FORM = "EDIT_COUPON_FORM"
export const CHECK_EXIST_COUPON_NAME = "CHECK_EXIST_COUPON_NAME"
export const BEFORE_CHECK_EXIST_COUPON_NAME = "BEFORE_CHECK_EXIST_COUPON_NAME"
export const COUPON_SHOW_LOADING = "COUPON_SHOW_LOADING"
export const SET_COUPON_TO_EDIT = "SET_COUPON_TO_EDIT"
export const CLEAN_COUPON_UPDATE = "CLEAN_COUPON_UPDATE"
export const CLEAN_COUPON_REMOVE = "CLEAN_COUPON_REMOVE"
export const SET_EMPTY_COUPON = "SET_EMPTY_COUPON"

export const ON_ORDER_COUPON_REMOVE_SUCCESS = "ON_ORDER_COUPON_REMOVE_SUCCESS"
export const ON_ORDER_COUPON_REMOVE_FAIL = "ON_ORDER_COUPON_REMOVE_FAIL"
export const ON_ORDER_COUPON_ADD_SUCCESS = "ON_ORDER_COUPON_ADD_SUCCESS"
export const ON_ORDER_COUPON_ADD_FAIL = "ON_ORDER_COUPON_ADD_FAIL"
export const ON_START_ORDERS_LOADING = "ON_START_ORDERS_LOADING"

//ERRORS
export const UNAUTHORIZED = "UNAUTHORIZED"
export const RESET_ERROR = "RESET_ERROR"
export const SET_AUTHORIZATION_MANUAL = "SET_AUTHORIZATION_MANUAL"

//SERVICESUPPLIERS
export const ON_GET_ALL_SUPPLIERS_SUCCESS = "ON_GET_ALL_SUPPLIERS_SUCCESS"
export const ON_GET_ALL_SUPPLIERS_FAIL = "ON_GET_ALL_SUPPLIERS_FAIL"
export const SET_SUPPLIER_TO_EDIT = "SET_SUPPLIER_TO_EDIT"
export const EDIT_SUPPLIER_FORM = "EDIT_SUPPLIER_FORM"
export const ON_UPDATE_SUPPLIER = "ON_UPDATE_SUPPLIER"
export const CLEAN_SUPPLIER_UPDATE = "CLEAN_SUPPLIER_UPDATE"
export const ON_REMOVE_SUPPLIER = "ON_REMOVE_SUPPLIER"
export const CLEAN_SUPPLIER_REMOVE = "CLEAN_SUPPLIER_REMOVE"
export const CLEAN_SUPPLIER_FORM = "CLEAN_SUPPLIER_FORM"
export const ON_CREATE_SUPPLIER = "ON_CREATE_SUPPLIER"
export const SUPPLIER_SHOW_LOADING = "SUPPLIER_SHOW_LOADING"
export const ON_GET_SELECT_SUPPLIERS_SUCCESS = "ON_GET_SELECT_SUPPLIERS_SUCCESS"
export const ON_GET_SELECT_SUPPLIERS_FAIL = "ON_GET_SELECT_SUPPLIERS_FAIL"

//ROUTES
export const ON_ROUTE_SHOW_LOADING = "ON_ROUTE_SHOW_LOADING"
export const ON_GET_ROUTES_SUCCESS = "ON_GET_ROUTES_SUCCESS"
export const ON_GET_ROUTES_FAIL = "ON_GET_ROUTES_FAIL"
export const ON_SET_ROUTE = "ON_SET_ROUTE"
export const ON_UPDATE_ROUTE_SUCCESS = "ON_UPDATE_ROUTE_SUCCESS"
export const ON_UPDATE_ROUTE_FAIL = "ON_UPDATE_ROUTE_FAIL"
export const ON_CREATE_ROUTE_SUCCESS = "ON_CREATE_ROUTE_SUCCESS"
export const ON_CREATE_ROUTE_FAIL = "ON_CREATE_ROUTE_FAIL"

export const ON_ROUTE_STATIONS_SHOW_LOADING = "SUPPLIER_SHOW_LOADING"
export const GET_STATIONS_BY_ROUTE_SUCCESS = "GET_STATIONS_BY_ROUTE_SUCCESS"
export const GET_STATIONS_BY_ROUTE_FAIL = "GET_STATIONS_BY_ROUTE_FAIL"

export const ON_CHECK_EXIST_ROUTE_NAME_SUCCESS =
  "ON_CHECK_EXIST_ROUTE_NAME_SUCCESS"
export const ON_CHECK_EXIST_ROUTE_NAME_FAIL = "ON_CHECK_EXIST_ROUTE_NAME_FAIL"
export const ON_CHECK_EXIST_ROUTE_NAME_LOADING =
  "ON_CHECK_EXIST_ROUTE_NAME_LOADING"

export const ON_DELETE_ROUTE_SUCCESS = "ON_DELETE_ROUTE_SUCCESS"
export const ON_DELETE_ROUTE_FAIL = "ON_DELETE_ROUTE_FAIL"
export const ON_CLOSE_ROUTE_NOTIFICATION = "ON_CLOSE_ROUTE_NOTIFICATION"

//CITIES
export const ON_GET_CITIES_SUCCESS = "ON_GET_CITIES_SUCCESS"
export const ON_GET_CITIES_FAIL = "ON_GET_CITIES_FAIL"
export const ON_CITIES_LOADING = "ON_CITIES_LOADING"
export const SET_CURRENT_CITY = "SET_CURRENT_CITY"
export const CLOSE_CITY_NOTIFICATION = "CLOSE_CITY_NOTIFICATION"
export const ON_ADD_CITY_SUCCESS = "ON_ADD_CITY_SUCCESS"
export const ON_ADD_CITY_FAIL = "ON_ADD_CITY_FAIL"
export const ON_CITIES_FILTER_BY_TEXT = "ON_CITIES_FILTER_BY_TEXT"
export const ON_CITIES_SET_PAGE = "ON_CITIES_SET_PAGE"
export const CLEAR_LOCAL_CITIES_FILTER_SEARCH =
  "CLEAR_LOCAL_CITIES_FILTER_SEARCH"
export const ON_GET_ALL_CITIES_SUCCESS = "ON_GET_ALL_CITIES_SUCCESS"
export const EDIT_CITY_FORM = "EDIT_CITY_FORM"
export const ON_CREATE_CITY_SUCCESS = "ON_CREATE_CITY_SUCCESS"
export const ON_CREATE_CITY_FAIL = "ON_CREATE_CITY_FAIL"
export const ON_EXTERNAL_CITIES_SUCCESS = "ON_EXTERNAL_CITIES_SUCCESS"
export const CLEAN_CITY_FORM = "CLEAN_CITY_FORM"
export const ON_GET_DISTRICTS_SUCCESS = "ON_GET_DISTRICTS_SUCCESS"
export const ON_SELECT_CITY = "ON_SELECT_CITY"
export const ON_UPDATE_CITY = "ON_UPDATE_CITY"
export const CLEAR_CITIES_LIST = "CLEAR_CITIES_LIST"
export const ON_UPDATE_CITY_FAILED = "ON_UPDATE_CITY_FAILED"
export const GET_POSTAL_CODE_INVALID = "GET_POSTAL_CODE_INVALID"
export const ON_CLOSE_POSTAL_NOTIFICATION_INVALID =
  "ON_CLOSE_POSTAL_NOTIFICATION_INVALID"

//USER HISTORY
export const SET_USER_HISTORY_CURRENT_USER = "SET_USER_HISTORY_CURRENT_USER"
export const ON_LOADING_USER_HISTORY = "ON_LOADING_USER_HISTORY"
export const GET_USER_HISTORY_SUCCESS = "GET_USER_HISTORY_SUCCESS"
export const GET_USER_HISTORY_FAIL = "GET_USER_HISTORY_FAIL"
export const CLEAR_USER_HISTORY_LIST = "CLEAR_USER_HISTORY_LIST"
export const SET_USER_HISTORY_SEARCH = "SET_USER_HISTORY_SEARCH"

//ALIVE
export const FETCH_DOWN_STATIONS = "FETCH_DOWN_STATIONS"

//STATIONS VOLTAGE
export const FETCH_STATIONS_VOLTAGE = "FETCH_STATIONS_VOLTAGE"
export const FETCH_STATION_VOLTAGE = "FETCH_STATION_VOLTAGE "

//LOCKERS_INFRA_SENSOR
export const SET_LOCKERS_INFRA_SENSOR_STATION =
  "SET_LOCKERS_INFRA_SENSOR_STATION"
export const ON_LOADING_LOCKERS_INFRA_SENSOR = "ON_LOADING_LOCKERS_INFRA_SENSOR"
export const GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS =
  "GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS"
export const GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL =
  "GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL"
export const SET_LOCKERS_INFRA_SENSOR_SEARCH = "SET_LOCKERS_INFRA_SENSOR_SEARCH"

//
export const ON_PUT_ORDER_TO_LOCKER_SUCCESS = "ON_PUT_ORDER_TO_LOCKER_SUCCESS"
export const ON_PUT_ORDER_TO_LOCKER_FAIL = "ON_PUT_ORDER_TO_LOCKER_FAIL"

export const ON_LOADING_WAITINGFORPICKUP_ORDERS =
  "ON_LOADING_WAITINGFORPICKUP_ORDERS"
export const ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS =
  "ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS"
export const ON_GET_WAITINGFORPICKUP_ORDERS_FAIL =
  "ON_GET_WAITINGFORPICKUP_ORDERS_FAIL"
export const ON_CREATE_RETURNS_ORDER_SUCCESS = "ON_CREATE_RETURNS_ORDER_SUCCESS"
export const ON_CREATE_RETURNS_ORDER_FAIL = "ON_CREATE_RETURNS_ORDER_FAIL"
export const ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS =
  "ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS"
export const CLEAR_CREATE_RETURNS_ORDER_SUCCESS =
  "CLEAR_CREATE_RETURNS_ORDER_SUCCESS"

//ORDERS SACKS
export const ON_LOADING_ORDERS_SACKS = "ON_LOADING_ORDERS_SACKS"
export const ON_CREATE_ORDER_SACK_SUCCESS = "ON_CREATE_ORDER_SACK_SUCCESS"
export const ON_CREATE_ORDER_SACK_FAIL = "ON_CREATE_ORDER_SACK_FAIL"
export const GET_ORDER_SACKS_LIST_SUCCESS = "GET_ORDER_SACKS_LIST_SUCCESS"
export const GET_ORDER_SACKS_LIST_FAIL = "GET_ORDER_SACKS_LIST_FAIL"
export const ON_FETCH_ORDERS_FOR_SACK_SUCCESS =
  "ON_FETCH_ORDERS_FOR_SACK_SUCCESS"
export const ON_FETCH_ORDERS_FOR_SACK_FAIL = "ON_FETCH_ORDERS_FOR_SACK_FAIL"
export const ON_PUT_ORDER_TO_SACK_SUCCESS = "ON_PUT_ORDER_TO_SACK_SUCCESS"
export const ON_PUT_ORDER_TO_SACK_FAIL = "ON_PUT_ORDER_TO_SACK_FAIL"
export const ON_PUT_ORDERS_TO_SACK_SUCCESS = "ON_PUT_ORDERS_TO_SACK_SUCCESS"
export const ON_PUT_ORDERS_TO_SACK_FAIL = "ON_PUT_ORDERS_TO_SACK_FAIL"
export const ON_GET_SACK_ORDERS_SUCCESS = "ON_GET_SACK_ORDERS_SUCCESS"
export const ON_GET_SACK_ORDERS_FAIL = "ON_GET_SACK_ORDERS_FAIL"
export const ON_SET_ORDERS_SACK_DELEVERED_SUCCESS =
  "ON_SET_ORDERS_SACK_DELEVERED_SUCCESS"
export const ON_SET_ORDERS_SACK_DELEVERED_FAIL =
  "ON_SET_ORDERS_SACK_DELEVERED_FAIL"
export const CLEAR_ORDERS_SACKS = "CLEAR_ORDERS_SACKS"
export const CLEAR_SELECT_ORDERS_SACK = "CLEAR_SELECT_ORDERS_SACK"
export const ON_SEARCH_SACK_SUCCESS = "ON_SEARCH_SACK_SUCCESS"
export const ON_SEARCH_SACK_FAIL = "ON_SEARCH_SACK_FAIL"
export const CLEAR_SEARCH_SACK = "CLEAR_SEARCH_SACK"
export const CLEAR_GET_SACKS_ORDERS = "CLEAR_GET_SACKS_ORDERS"
export const ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS =
  "ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_SUCCESS"
export const ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL =
  "ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_FAIL"
export const CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE =
  "CLEAR_SET_ORDERS_SACK_DELEVERED_BY_BARCODE"
export const ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT =
  "ON_SET_ORDERS_SACK_DELEVERED_BY_BARCODE_CONFLICT"
export const ON_SEARCH_SACKS_LIST_SUCCESS = "ON_SEARCH_SACKS_LIST_SUCCESS"
export const ON_SEARCH_SACKS_LIST_FAIL = "ON_SEARCH_SACKS_LIST_FAIL"
export const CLEAR_SEARCH_SACKS_LIST = "CLEAR_SEARCH_SACKS_LIST"
export const ON_GET_WAREHOUSE_LOCATION_SUCCESS =
  "ON_GET_WAREHOUSE_LOCATION_SUCCESS"
export const ON_GET_WAREHOUSE_LOCATION_FAIL = "ON_GET_WAREHOUSE_LOCATION_FAIL"

// ORDERS SACKS ONEPROJECT

export const ON_ONEPROJECT_LOADING_ORDERS_SACKS =
  "ON_ONEPROJECT_LOADING_ORDERS_SACKS"
export const ON_ONEPROJECT_CREATE_ORDER_SACK_SUCCESS =
  "ON_ONEPROJECT_CREATE_ORDER_SACK_SUCCESS"
export const ON_ONEPROJECT_CREATE_ORDER_SACK_FAIL =
  "ON_ONEPROJECT_CREATE_ORDER_SACK_FAIL"
export const GET_ONEPROJECT_ORDER_SACKS_LIST_SUCCESS =
  "GET_ONEPROJECT_ORDER_SACKS_LIST_SUCCESS"
export const GET_ONEPROJECT_ORDER_SACKS_LIST_FAIL =
  "GET_ONEPROJECT_ORDER_SACKS_LIST_FAIL"
export const ON_ONEPROJECT_FETCH_ORDERS_FOR_SACK_SUCCESS =
  "ON_ONEPROJECT_FETCH_ORDERS_FOR_SACK_SUCCESS"
export const ON_ONEPROJECT_FETCH_ORDERS_FOR_SACK_FAIL =
  "ON_ONEPROJECT_FETCH_ORDERS_FOR_SACK_FAIL"
export const ON_ONEPROJECT_PUT_ORDER_TO_SACK_SUCCESS =
  "ON_ONEPROJECT_PUT_ORDER_TO_SACK_SUCCESS"
export const ON_ONEPROJECT_PUT_ORDER_TO_SACK_FAIL =
  "ON_ONEPROJECT_PUT_ORDER_TO_SACK_FAIL"
export const ON_ONEPROJECT_GET_SACK_ORDERS_SUCCESS =
  "ON_ONEPROJECT_GET_SACK_ORDERS_SUCCESS"
export const ON_ONEPROJECT_GET_SACK_ORDERS_FAIL =
  "ON_ONEPROJECT_GET_SACK_ORDERS_FAIL"
export const ON_ONEPROJECT_SET_ORDERS_SACK_DELEVERED_SUCCESS =
  "ON_ONEPROJECT_SET_ORDERS_SACK_DELEVERED_SUCCESS"
export const ON_ONEPROJECT_SET_ORDERS_SACK_DELEVERED_FAIL =
  "ON_ONEPROJECT_SET_ORDERS_SACK_DELEVERED_FAIL"
export const CLEAR_ONEPROJECT_SELECT_ORDERS_SACK =
  "CLEAR_ONEPROJECT_SELECT_ORDERS_SACK"
export const CLEAR_ONEPROJECT_ORDERS_SACKS = "CLEAR_ONEPROJECT_ORDERS_SACKS"

//ISRAEL POST
export const ON_GET_DELIVERY_ISRAEL_POST_SUCCESS =
  "ON_GET_DELIVERY_ISRAEL_POST_SUCCESS"
export const ON_GET_DELIVERY_ISRAEL_POST_FAIL =
  "ON_GET_DELIVERY_ISRAEL_POST_FAIL"
export const ON_CLEAR_DELIVERY_ISRAEL_POST = "ON_CLEAR_DELIVERY_ISRAEL_POST"
// ORDR IMAGES
export const ON_CHECK_FOR_IMAGES_SUCCESS = "ON_CHECK_FOR_IMAGES_SUCCESS"
export const ON_CHECK_FOR_IMAGES_FAIL = "ON_CHECK_FOR_IMAGES_FAIL"
export const ON_GET_ORDER_IMAGES_SUCCESS = "ON_GET_ORDER_IMAGES_SUCCESS"
export const ON_GET_ORDER_IMAGES_FAIL = "ON_GET_ORDER_IMAGES_FAIL"
export const ON_CLEAR_ORDER_IMAGES = "ON_CLEAR_ORDER_IMAGES"

// ROUTE AREA CODE
export const ON_GET_ROUTE_AREA_CODE_SUCCESS = "ON_GET_ROUTE_AREA_CODE_SUCCESS"
export const ON_GET_ROUTE_AREA_CODE_FAIL = "ON_GET_ROUTE_AREA_CODE_FAIL"
export const ON_CREATE_ROUTE_AREA_CODE_SUCCESS =
  "ON_CREATE_ROUTE_AREA_CODE_SUCCESS"
export const ON_CREATE_ROUTE_AREA_CODE_FAIL = "ON_CREATE_ROUTE_AREA_CODE_FAIL"
export const ON_EDIT_ROUTE_AREA_CODE_SUCCESS = "ON_EDIT_ROUTE_AREA_CODE_SUCCESS"
export const ON_EDIT_ROUTE_AREA_CODE_FAIL = "ON_EDIT_ROUTE_AREA_CODE_FAIL"
export const ON_SELECT_EDIT_ROUTE_AREA_CODE = "ON_SELECT_EDIT_ROUTE_AREA_CODE"
export const CLEAR_AREA_ROUTE_CODE = "CLEAR_AREA_ROUTE_CODE"

// STATION TASKS
export const ON_STATION_TASKS_LOADING = "ON_STATION_TASKS_LOADING"
export const ON_GET_STATION_TASKS_SUCCESS = "ON_GET_STATION_TASKS_SUCCESS"
export const ON_GET_STATION_TASKS_FAIL = "ON_GET_STATION_TASKS_FAIL"
export const ON_SELECT_EDIT_TASK = "ON_SELECT_EDIT_TASK"
export const ON_UPDATE_STATION_TASKS_SUCCESS = "ON_UPDATE_STATION_TASKS_SUCCESS"
export const ON_UPDATE_STATION_TASKS_FAIL = "ON_UPDATE_STATION_TASKS_FAIL"
export const CLEAR_STATION_TASKS = "CLEAR_STATION_TASKS"
export const ON_GET_STATION_TASK_HISTORY_SUCCESS =
  "ON_GET_STATION_TASK_HISTORY_SUCCESS"
export const ON_GET_STATION_TASK_HISTORY_FAIL =
  "ON_GET_STATION_TASK_HISTORY_FAIL"
export const ON_CREATE_STATION_TASKS_SUCCESS = "ON_CREATE_STATION_TASKS_SUCCESS"
export const ON_CREATE_STATION_TASKS_FAIL = "ON_CREATE_STATION_TASKS_FAIL"
export const CLEAR_STATION_TASKS_HISTORY = "CLEAR_STATION_TASKS_HISTORY"
export const ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS =
  "ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS"
export const ON_TOGGLE_IS_ACTIVE_TASKS_FAIL = "ON_TOGGLE_IS_ACTIVE_TASKS_FAIL"
export const ON_GET_STATION_TASK_PHOTO_SUCCESS =
  "ON_GET_STATION_TASK_PHOTO_SUCCESS"
export const ON_GET_STATION_TASK_PHOTO_FAIL = "ON_GET_STATION_TASK_PHOTO_FAIL"
export const CLEAR_GET_STATION_TASKS_PHOTO = "CLEAR_GET_STATION_TASKS_PHOTO"

// ORDER IN LOCKER EXTEND
export const ON_IN_LOCKER_EXTEND_SUCCESS = "ON_IN_LOCKER_EXTEND_SUCCESS"
export const ON_IN_LOCKER_EXTEND_FAIL = "ON_IN_LOCKER_EXTEND_FAIL"
export const CLEAR_IN_LOCKER_EXTEND = "CLEAR_IN_LOCKER_EXTEND"

export const DELIVERY_LOGIN_CODE_RESET_SUCCESS =
  "DELIVERY_LOGIN_CODE_RESET_SUCCESS"
export const DELIVERY_LOGIN_CODE_RESET_FAIL = "DELIVERY_LOGIN_CODE_RESET_FAIL"
export const CLEAR_DELIVERY_LOGIN_CODE_RESET = "CLEAR_DELIVERY_LOGIN_CODE_RESET"

// BRANCH CARD
export const ON_BRANCH_CARD_LOADING = "ON_BRANCH_CARD_LOADING"
export const ON_GET_BRANCH_CARD_SUCCESS = "ON_GET_BRANCH_CARD_SUCCESS"
export const ON_GET_GET_BRANCH_CARD_FAIL = "ON_GET_GET_BRANCH_CARD_FAIL"
export const CLEAR_UPDATE_BRANCH_CARD = "CLEAR_UPDATE_BRANCH_CARD"
export const ON_BRANCH_CARD_UPDATE_SUCCESS = "ON_BRANCH_CARD_UPDATE_SUCCESS"
export const ON_BRANCH_CARD_UPDATE_FAIL = "ON_BRANCH_CARD_UPDATE_FAIL"
export const CHANGE_TARGET_CUSTOMER_SUCCESS = "CHANGE_TARGET_USER_SUCCESS"
export const CHANGE_TARGET_CUSTOMER_FAIL = "CHANGE_TARGET_USER_FAIL"
export const CLEAR_CHANGE_TARGET_CUSTOMER = "CLEAR_CHANGE_TARGET_CUSTOMER"
export const CLEAR_GET_BRANCH_CARD = "CLEAR_GET_BRANCH_CARD"

//
export const CHANGE_TARGET_STATION_SUCCESS = "CHANGE_TARGET_STATION_SUCCESS"
export const CHANGE_TARGET_STATION_FAIL = "CHANGE_TARGET_STATION_FAIL"
export const ON_GET_DONE_ORDER_TYPES_SUCCESS = "ON_GET_DONE_ORDER_TYPES_SUCCESS"
export const ON_GET_DONE_ORDER_TYPES_FAILURE = "ON_GET_DONE_ORDER_TYPES_FAILURE"
export const CLEAR_CHANGE_TARGET_STATION = "CLEAR_CHANGE_TARGET_STATION"
export const CHANGE_SOURCE_STATION_SUCCESS = "CHANGE_SOURCE_STATION_SUCCESS"
export const CHANGE_SOURCE_STATION_FAIL = "CHANGE_SOURCE_STATION_FAIL"
export const CLEAR_CHANGE_SOURCE_STATION = "CLEAR_CHANGE_SOURCE_STATION"

//ROUTE USER
export const ON_ROUTE_USER_LOADING = "ON_ROUTE_USER_LOADING"
export const ON_GET_ROUTE_USERS_SUCCESS = "ON_GET_ROUTE_USERS_SUCCESS"
export const ON_GET_ROUTE_USERS_FAIL = "ON_GET_ROUTE_USERS_FAIL"

export const ON_CREATE_ROUTE_USER_SUCCESS = "ON_CREATE_ROUTE_USER_SUCCESS"
export const ON_CREATE_ROUTE_USER_FAIL = "ON_CREATE_ROUTE_USER_FAIL"
export const ON_GET_EXTERNAL_DRIVERS_SUCCESS = "ON_GET_EXTERNAL_DRIVERS_SUCCESS"
export const ON_GET_EXTERNAL_DRIVERS_FAIL = "ON_GET_EXTERNAL_DRIVERS_FAIL"
export const ON_REMOVE_ROUTE_USER_SUCCESS = "ON_REMOVE_ROUTE_USER_SUCCESS"
export const ON_REMOVE_ROUTE_USER_FAIL = "ON_REMOVE_ROUTE_USER_FAIL"
export const ON_CLOSE_ROUTE_USER_NOTIFICATION =
  "ON_CLOSE_ROUTE_USER_NOTIFICATION"
export const ON_SET_CURRENT_USERS_ROUTE = "ON_SET_CURRENT_USERS_ROUTE"

// LOCKER STATUS
export const FETCH_ALL_LOCKERS_STATUSES_SUCCESS =
  "FETCH_ALL_LOCKERS_STATUSES_SUCCESS"
export const FETCH_ALL_LOCKERS_LAYOUT_SUCCESS =
  "FETCH_ALL_LOCKERS_LAYOUT_SUCCESS"
export const CLEAR_LOCKERS_STATUSES = "CLEAR_LOCKERS_STATUSES"
export const ON_SELECT_STATION_ID = "ON_SELECT_STATION_ID"

//USER SUBSCRIPTIONS
export const SHOW_SUBSCRIPTIONS_LOADING = "SHOW_SUBSCRIPTIONS_LOADING"
export const ON_GET_USER_SUBSCRIPTIONS_SUCCESS =
  "ON_GET_USER_SUBSCRIPTIONS_SUCCESS"
export const ON_GET_USER_SUBSCRIPTIONS_FAIL = "ON_GET_USER_SUBSCRIPTIONS_FAIL"
export const ON_CANCEL_USER_SUBSCRIPTION_SUCCESS =
  "ON_CANCEL_USER_SUBSCRIPTION_SUCCESS"
export const ON_CANCEL_USER_SUBSCRIPTION_FAIL =
  "ON_CANCEL_USER_SUBSCRIPTION_FAIL"
export const CLEAN_USER_SUBSCRIPTIONS = "CLEAN_USER_SUBSCRIPTIONS"

//ORDER TRACKING
export const SET_ORDER_TRACKING_SUCCESS = "SET_ORDER_TRACKING_SUCCESS"
export const SET_ORDER_TRACKING_FAIL = "SET_ORDER_TRACKING_FAIL"
export const CLEAR_SET_ORDER_TRACKING = "CLEAR_SET_ORDER_TRACKING"

export const CHANGE_PACKAGE_NUMBER_REQUEST = "CHANGE_PACKAGE_NUMBER_REQUEST"
export const CHANGE_PACKAGE_NUMBER_SUCCESS = "CHANGE_PACKAGE_NUMBER_SUCCESS"
export const CHANGE_PACKAGE_NUMBER_FAIL = "CHANGE_PACKAGE_NUMBER_FAIL"
export const CLEAR_CHANGE_PACKAGE_NUMBER = "CLEAR_CHANGE_PACKAGE_NUMBER"
export const MARK_IN_ORDER_HISTORY_SUCCESS = "MARK_IN_ORDER_HISTORY_SUCCESS"
export const MARK_IN_ORDER_HISTORY_FAIL = "MARK_IN_ORDER_HISTORY_FAIL"
export const CLEAR_MARK_IN_ORDER_HISTORY = "CLEAR_MARK_IN_ORDER_HISTORY"

// STORAGE SACKS
export const GET_ORDER_STORAGE_STATUSES = "GET_ORDER_STORAGE_STATUSES"
export const GET_ORDER_STORAGE_STATUSES_FAIL = "GET_ORDER_STORAGE_STATUSES_FAIL"
export const GET_STORAGE_SACK_NUMBERS_SUCSESS =
  "GET_STORAGE_SACK_NUMBERS_SUCSESS"
export const GET_STORAGE_SACK_NUMBERS_FAIL = "GET_STORAGE_SACK_NUMBERS_FAIL"
export const ON_CLOSE_STORAGE_SACK_REQUEST = "ON_CLOSE_STORAGE_SACK_REQUEST"
export const ON_CLOSE_STORAGE_SACK_SUCCESS = "ON_CLOSE_STORAGE_SACK_SUCCESS"
export const CLEAR_CLOSE_STORAGE_SACK = "CLEAR_CLOSE_STORAGE_SACK,"
export const ON_CLOSE_STORAGE_SACK_FAIL = "ON_CLOSE_STORAGE_SACK_FAIL"
export const ON_ORDER_IN_SACK_SUCCESS = "ON_ORDER_IN_SACK_SUCCESS"
export const ON_ORDER_IN_SACK_FAIL = "ON_ORDER_IN_SACK_FAIL"
export const ON_SEARCH_STORAGE_SACK_SUCCESS = "ON_SEARCH_STORAGE_SACK_SUCCESS"
export const ON_SEARCH_STORAGE_SACK_FAIL = "ON_SEARCH_STORAGE_SACK_FAIL"
export const CLEAR_SEARCH_STORAGE_SACKS = "CLEAR_SEARCH_STORAGE_SACKS"
export const ON_GET_STORAGE_SACK_ORDERS_SUCCESS =
  "ON_GET_STORAGE_SACK_ORDERS_SUCCESS"
export const ON_GET_STORAGE_SACK_ORDERS_FAIL = "ON_GET_STORAGE_SACK_ORDERS_FAIL"

export const ON_CLOSE_ALL_STORAGE_SACKS_REQUEST =
  "ON_CLOSE_ALL_STORAGE_SACKS_REQUEST"
export const ON_CLOSE_ALL_STORAGE_SACKS_SUCCESS =
  "ON_CLOSE_STORAGE_SACKS_SUCCESS"
export const ON_CLOSE_ALL_STORAGE_SACKS_FAIL = "ON_CLOSE_STORAGE_SACKS_FAIL"
export const CLEAR_CLOSE_ALL_STORAGE_SACKS = "CLEAR_CLOSE_ALL_STORAGE_SACKS"

export const GET_SACK_SUMMARY_BY_STATION_SUCCESS =
  "GET_SACK_SUMMARY_BY_STATION_SUCCESS"
export const GET_SACK_SUMMARY_BY_STATION_FAIL =
  "GET_SACK_SUMMARY_BY_STATION_FAIL"

export const ON_GET_SEARCHED_ORDERS_HISTORY_LOADING =
  "ON_GET_SEARCHED_ORDERS_HISTORY_LOADING"
export const ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS =
  "ON_GET_SEARCHED_ORDERS_HISTORY_SUCCESS"
export const ON_GET_SEARCHED_ORDERS_HISTORY_FAIL =
  "ON_GET_SEARCHED_ORDERS_HISTORY_FAIL"
export const CLEAR_SEARCHED_ORDERS_HISTORY = "CLEAR_SEARCHED_ORDERS_HISTORY"
